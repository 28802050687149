@import "../../../../../view/styles/variable";

.link-button {
  margin: 0 0 10px;
  line-height: 21px;
  color: $color-blue;
  word-break: break-all;
  cursor: pointer;

  &:last-child {
    margin: 0;
  }

  &:hover {
    text-decoration: underline;
  }
}

.bounty-type-participation-condition {
  &-container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &-label {
    margin-bottom: 20px;
    font-weight: 500;
    line-height: 30px;
  }
}

.faucet-type-participation-condition {
  &-container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &-label {
    margin-bottom: 20px;
    font-weight: 500;
    line-height: 30px;
  }
}
