@import 'src/view/styles/variable';

.transaction-status{
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  padding: 24px;
  background-color: $color-white;
  border-radius: 16px;
  margin-bottom: 20px;
  &-label{
    font-size: 20px;
    font-weight: 700;
    line-height: 130%;
  }
  &-header{
    display: flex;
    align-items: center;
    gap: 8px;

    &-icon{
      width: 24px;
      height: 24px;
      object-fit: cover;
    }
    &-title{
      font-size: 16px;
      font-weight: 700;
      line-height: 130%;
    }
  }
  &-content{
    display: flex;
    padding-left: 10px;
    &-description{
      padding-left: 22px;
      font-size: 14px;
      font-weight: 400;
      line-height: 160%;
    }
  }
}
.transaction-hash-with-link{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 10px;
  padding: 14px 20px;
  border-radius: 8px;
  border: 2px solid #E7E9EF;
  &-hash{
    color: #5888FF;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    cursor: pointer;
  }
  &-button{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 21px;
    background-color: #EFEFEF;
    border-radius: 8px;
    cursor: pointer;
    &:hover{
      background-color: #5888FF;
    }
  }
}
