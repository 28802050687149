@import "../../../view/styles/variable";

.create-new-key-modal-content {
  &-input-label {
    margin-bottom: 8px;
    font-size: $fs-normal;
    font-weight: $fw-medium;
    color: $fc-main;
    line-height: 21px;
  }

  &-description {
    margin-top: 32px;
    font-size: $fs-normal;
    font-weight: $fw-medium;
    color: $fc-main;
    line-height: 21px;

    &-row {
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.create-new-key-modal-button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  width: 100%;
}
