.register-wallet {
  &-purpose-type-container {
    display: flex;
    gap: 20px;
  }

  &-chain-selector-container {
    display: flex;
    gap: 10px;
    width: 100%;
  }

  &-button-container {
    display: flex;
    justify-content: flex-end;
  }
}
