@import "../../../../styles/variable";
.popular-contracts{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.filter-container {
  display: flex;
  flex-direction: row;
  height: 22px;

  &-index {
    margin-right: 8px;
    font-weight: $fw-medium;
    font-size: $fs-normal;
    color: $fc-main;
    line-height: 21px;
  }

  &-conditions {
    display: flex;
    align-items: center;
  }
}

.contract-type-label {
  width: fit-content;
  padding: 4px 10px;
  border-radius: 17px;
  cursor: pointer;
  color: $color-white;
  font-weight: $fw-medium;
  &:not(:last-child) {
    margin-right: 10px;
  }
}

.contract-type-container {
  &:not(:last-child) {
    margin-right: 6px;
  }

  .contract-filter-label {
    width: fit-content;
    padding: 4px 10px;
    border-radius: 17px;
    border: 1px solid #d9dfe4;
    background-color: transparent;
    cursor: pointer;
    font-weight: $fw-medium;
    color: #999999;
    transition: all 0.15s ease-in;

    &:hover {
      background-color: #d9dfe4;
      color: $fc-main;
    }
  }
}
.page-sub-title {
  font-size: 28px;
  font-weight: 600;
  line-height: 36px;
  &.sticky-point{
    position: sticky;
    top: 68px;
  }
}
.search-box {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 66px;
  margin-top: 20px;
  .search-input-box {
    position: relative;
    width: 696px;
    height: 50px;
    .search-input {
      width: 696px;
      height: 50px;
      padding: 0 45px 0 20px;
      border: 2px solid #efefef;
      border-radius: 8px;
      font-size: 16px;
      &::placeholder {
        font-size: 16px;
        font-weight: 500;
        color: #AAAFBE;
      }
      &:focus {
        border: 2px solid #5888ff;
      }
    }
    .search-btn {
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translateY(-50%);
      .search-icon {
        width: 16px;
        height: 16px;
      }
    }
  }
}
.selected-filter-type{
  .selected-filter-type-box{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 10px;

    font-weight: 500;
    .selected-filter-type-title{
      color: #7F879E;
    }
  }
 
  .selected-filter-type-solution{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 26px;
    padding: 0 3px 0 10px;
    border: 1px solid #5888ff;
    border-radius: 17px;
    font-size: 14px;
    color: #5888ff;
  }
  .selected-filter-type-standard{
    display: flex;
    line-height: 20px;
    align-items: center;
    justify-content: center;
    height: 26px;
    padding: 0 3px 0 10px;
    border-radius: 17px;
    font-size: 14px;
    color: #5888ff;
  }
  .selected-filter-delete-icon{
      width: 20px;
      height: 20px;
      margin-left: 7px;
      vertical-align: -5px;
      cursor: pointer;
    }
  .selected-filter-clear{
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    color: #5888ff;
    text-decoration: underline;
  }
}
.pagination-box{
  margin-top: 40px;
}