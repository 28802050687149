@import "../../../../../styles/variable";
@import "../../../../../styles/_mixins";

.blockchain-engine-info {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  gap: 20px;
  padding: 24px;
  width: 100%;
  border-radius: 16px;
  background: radial-gradient(268.23% 141.42% at 0% 0%, #fff 0%, #f5f8fd 100%);
  border: 1px solid #e2ebff;
  cursor: pointer;

  &.selected {
    border-color: #5888ff;
  }

  &.disabled {
    border-radius: 16px;
    border: 1px solid #efefef;
    background: #f9f9f9;
    cursor: default;
  }

  &.unable {
    cursor: default;
  }

  .engine-radio {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
  }

  .engine-disabled-img {
    position: absolute;
    top: -3px;
    left: -6px;
    width: 93.5px;
    object-fit: contain;
  }

  .engine-radio {
    &:checked + label:before {
      border-color: $color-border-liner;
    }

    &:checked + label:after {
      transform: scale(1);
    }

    &:disabled {
      border: 1px solid #efefef;
      background: #d8d8d8;
    }
  }

  .engine-img {
    width: 60px;
    object-fit: contain;
  }

  .engine-info {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 420px;

    .engine-name {
      font-size: 24px;
      font-weight: 700;
      line-height: 30px;
    }

    .engine-description {
      @include multi-line-text-ellipsis(2);
      font-size: 16px;
      line-height: 21px;
    }
  }

  .engine-button {
    display: flex;
    width: 146px;
    height: 40px;
    padding: 0px 12px;
    align-items: center;
    gap: 10px;
    border-radius: 12px;
    background: var(--blue-04, #d8e1ee);
    font-weight: 500;
    color: #7f879e;
    cursor: pointer;

    &.disabled {
      cursor: default;
    }
  }
}

.blockchain-engine-info-coming-soon {
  position: absolute;
  top: -4px;
  left: 0;

  &-content {
    position: relative;

    &-front {
      padding: 2px 7px 2px 2px;
      background: linear-gradient(90deg, #6e87c9 0%, #6360d8 100.53%);
      font-weight: 700;
      font-size: 12px;
      border-top-right-radius: 12px;
      border-bottom-right-radius: 12px;
      color: #fff;
    }

    &-back {
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: -6px;
        width: 6px;
        height: 28px;
        background-color: #6e87c9;
        border-top-left-radius: 12px;
        border-bottom-left-radius: 12px;
      }

      &::after {
        content: "";
        position: absolute;
        top: 16px;
        left: -6px;
        width: 6px;
        height: 12px;
        background-color: #3b3a8e;
        border-top-left-radius: 12px;
        border-bottom-left-radius: 12px;
      }
    }
  }
}
