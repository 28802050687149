.row-field-container{
  display: flex;
  align-items: center;
  height: fit-content;
  &:not(:last-child){
    margin-bottom: 25px;
  }
  &-label{
    font-size: 16px;
    font-weight: 500;
    line-height: 21px;
    text-align: left;
    color: #7D828D;
    margin-right: 10px;
    width: 270px;
    padding-left: 10px;
  }
  &-content{
    display: flex;
    flex: 1;
  }
}
