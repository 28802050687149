@import "../../../../view/styles/variable";

#NotFound {
  --header-height: 60px;
  --footer-height: 111px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  // height: calc(100vh - var(--header-height) - var(--footer-height));
  height: calc(100vh - var(--header-height));
  background-color: #f5f6f8;

  .not-found-image {
    width: 358px;
    height: fit-content;

    &-src {
      width: 100%;
      object-fit: contain;
    }
  }

  .message-wrapper {
    margin: 40px 0 65px;
    color: $fc-main;

    .message-title {
      margin: 0 0 20px;
      text-align: center;
      font-size: 40px;
      font-weight: 700;
    }

    .message-description {
      font-size: 18px;
      text-align: center;
      white-space: pre-line;
    }
  }
}
