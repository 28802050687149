@import "../../../../../styles/variable";
@import "../../../../../styles/mixins";

.network-chain-client-selector {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.network-engine-version {
  font-weight: 400;
  color: $fc-main;
  line-height: 30px;
}

.network-chain-consensus-algorithm-wrapper {
  display: flex;
  align-items: center;
}
