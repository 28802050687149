.payment-gateway-date-picker-container {
  display: flex;
  align-items: center;
  gap: 5px;
}

.payment-gateway-deposit-creation-date {
  &.date {
    margin-bottom: 6px;
  }

  &.time {
    font-size: 14px;
  }
}

.payment-gateway-status-option-container {
  display: flex;
  align-items: center;
  gap: 24px;
  padding: 10px 0;
}

.payment-gateway-deposit-status {
  display: flex;
  justify-content: center;

  &-label {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;

    &.ready {
      color: #7f879e;
    }

    &.inprogress {
      color: #f90;
    }

    &.failed {
      color: #ff2e2e;
    }

    &.aborted {
      color: #3860e2;
    }

    &.success {
      color: #00da7d;
    }

    &-icon {
      margin-left: 4px;
      width: 19px;
      object-fit: contain;
    }
  }
}

.payment-gateway-smart-contract-deposit-copy-label {
  display: flex;
  justify-content: center;
  align-items: center;

  &-icon {
    width: 20px;
    cursor: pointer;

    &-src {
      width: 100%;
      object-fit: contain;
    }
  }
}

.payment-gateway-contract-deposit-filter-button-container {
  display: flex;
  justify-content: flex-end;
}
