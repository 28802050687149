@import "../../../../styles/variable";

.verify-email-modal {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 565px;

  &-image {
    margin-bottom: 40px;

    &-src {
      height: 78px;
      object-fit: contain;
    }
  }

  &-content {
    margin-bottom: 46px;
    line-height: 18px;
    color: $fc-main;
    text-align: center;

    &-email {
      color: $color-primary;
    }
  }

  &-receive {
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 18px;
    color: $fc-main;

    &-trigger {
      color: $color-primary;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &-button-container {
  }
}
