@import "../../../../styles/variable";

.notification-container {
  overflow: auto;
  max-height: 370px;

  &::-webkit-scrollbar {
    border-radius: 10px;
    background: rgba(88, 136, 255, 0.15);
    width: 5px;
    height: 8px;
    background-color: #f5f6f8;
    -webkit-transition: background-color 0.3s;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #b7b7b7;
  }
}
