@import "../../../../styles/mixins";
@import "../../../../styles/variable";

#JoinProject {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;

  .join-project {
    &-wrapper {
      display: flex;
      flex-direction: column;
      width: 70%;
      padding: 50px;
    }

    &-background {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 30%;
      background-color: #f7f7fc;

      &-image {
        height: 315px;
        object-fit: contain;

        &-src {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    &-header {
      display: flex;
      flex-direction: column;
      margin-bottom: 40px;

      &-title {
        font-size: 32px;
        font-weight: 700;
        line-height: 38px;
        letter-spacing: 0.005em;
        color: #11142d;
        margin-bottom: 10px;
      }

      &-description {
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0.005em;
        color: #11142d;
      }
    }

    &-selected {
      margin-bottom: 44px;

      &-notice {
        margin-bottom: 20px;
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
        color: $fc-main;

        &-count {
          font-size: 20px;
          line-height: 26px;
          color: $color-blue;
        }
      }

      &-list {
        &-label {
          margin-bottom: 10px;
          font-weight: 500;
          font-size: 16px;
          line-height: 21px;
          color: $fc-main;
        }

        &-wrapper {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          gap: 5px;
          min-height: 27px;
        }

        &-item {
          padding: 5px 10px;
          font-weight: 500;
          font-size: 12px;
          line-height: 15px;
          color: $fc-main;
          background-color: #fff;
          border: 1px solid $color-blue;
          border-radius: 16px;
        }
      }
    }

    &-label {
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      &-icon-box {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #ebf0f5;
        border-radius: 8px;
        background-color: #f9fafb;
        width: 34px;
        height: 34px;
        margin-right: 5px;

        &-img {
          width: 24px;
          object-fit: contain;
        }
      }

      &-text {
        font-weight: 700;
        font-size: 20px;
        line-height: 26px;
        color: #7d828d;
      }
    }

    &-invited-project-list {
      &-container {
        @include custom-scroll;
        flex: 1;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;
        margin-bottom: 40px;
        padding: 0 5px;
        overflow-y: auto;
      }
    }

    &-button-wrapper {
      display: flex;
      justify-content: flex-end;
    }
  }
}

.invited-project-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  width: 100%;
  height: 330px;
  max-height: 330px;
  background-color: #fff;
  border: 1px solid #e7e9ef;
  border-radius: 16px;

  &.selected {
    border: 1px solid $color-primary;
  }

  &-info {
    &-header {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      &-image {
        margin-right: 20px;
        width: 80px;
        height: 80px;
        background-color: #f5f7ff;
        border-radius: 100%;

        &-src {
          width: 100%;
          height: 100%;
          border-radius: 100%;
          object-fit: cover;
        }
      }

      &-detail {
        &-name {
          margin-bottom: 5px;
          width: 250px;
          font-weight: 500;
          font-size: 24px;
          line-height: 31px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          color: $fc-main;
        }

        &-owner {
          display: flex;
          align-items: center;

          &-label {
            margin-right: 10px;
            font-weight: 500;
            font-size: 14px;
            line-height: 18px;
            color: $fc-sub;
          }

          &-wrapper {
            display: flex;
            align-items: center;
          }

          &-image {
            margin-right: 5px;
            width: 40px;
            height: 40px;
            border-radius: 100%;

            &-src {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }

          &-name {
            width: 134px;
            font-weight: 500;
            font-size: 14px;
            line-height: 18px;
            color: $fc-main;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }

    &-description {
      @include multi-line-text-ellipsis(5);
      margin-bottom: 10px;
      height: 105px;
      max-height: 105px;
      font-weight: 400;
      font-size: 16px;
      line-height: 21px;
      color: $fc-main;
    }

    &-expiration-date {
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: $color-blue;
      // text-decoration: underline;
    }
  }

  &-button {
    &-label {
      display: flex;
      align-items: center;

      &-icon {
        margin-right: 5px;
        width: 10px;
        object-fit: contain;
      }
    }
  }
}
