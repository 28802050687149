@import "../../../../styles/variable";

.payment-gateway-withdrawal-creation-date {
  &.date {
    margin-bottom: 6px;
  }
  &.time {
    font-size: 14px;
  }
}

.payment-gateway-withdrawal-status {
  display: flex;
  justify-content: center;

  &-label {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;

    &-icon {
      margin-left: 4px;
      width: 19px;
      object-fit: contain;
    }

    &.send {
      color: #3860e2;
    }

    &.processing {
      color: #f90;
    }

    &.success {
      color: #00da7d;
    }
  }
}

.payment-gateway-date-picker-container {
  display: flex;
  align-items: center;
  gap: 5px;
  width: 100%;
}

.payment-gateway-status-option-container {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 10px 0;
  width: 100%;
}

.payment-gateway-withdrawal-filter-button-container {
  display: flex;
  justify-content: flex-end;
}
