@import "../../../../styles/variable";

.deploy-token-contract-modal-content{
  &-row{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 50px;
    &:not(:last-child){
      margin-bottom: 25px;
    }
    &-index{
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: $fc-main;
    }
    &-data{
      font-weight: 400;
      font-size: 16px;
      line-height: 21px;
      color: $fc-main;
    }
  }
}
