@import "../../../view/styles/variable";

.token-image-upload-modal-content {
  &-comment {
    margin-bottom: 30px;
    font-weight: $fw-medium;
    font-size: $fs-index;
    line-height: 23px;
    color: $fc-main;
  }
  &-slider {
    position: relative;
    display: flex;
    height: 130px;
    .swiper-button-prev {
      width: 30px;
      left: 0;
      z-index: 100;
      object-fit: contain;
    }
    .swiper-button-next {
      width: 30px;
      top: 50%;
      right: 0;
      object-fit: contain;
    }
  }
}

.swiper-wrapper {
  width: 470px !important;
  .swiper-slide {
    display: flex;
    justify-content: center;
  }
}

.default-image {
  width: 133px;
  height: 133px;
  border-radius: 50%;
  border: 1px solid $color-white;
  object-fit: contain;
  cursor: pointer;
  &:hover {
    box-sizing: border-box;
    border: 1px solid #98b4ff;
    transition: all ease-in-out 0.2s;
  }
}

.token-image-upload-modal-button-container {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}
