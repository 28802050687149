.invite-member-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 565px;

  &-row {
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;
    width: 100%;

    &-label {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      &-mark {
        background-color: #b1b1c1;
        margin-right: 10px;
        width: 5px;
        height: 5px;
      }

      &-text {
        font-size: 16px;
        font-weight: 500;
        line-height: 21px;
        color: #848a96;
      }
    }
  }

  &-description {
    margin-bottom: 40px;
    font-size: 16px;
    line-height: 21px;
    white-space: pre-line;
    color: #7d828d;
  }
}
