@import "../../../styles/variable";

.initial-content-template {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  min-width: 550px;
  border-radius: 20px;
  background-color: #fff;

  &-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    .initial-content-template-title {
      font-weight: 500;
      font-size: 32px;
      line-height: 38px;
      text-align: center;
      color: $fc-main;
    }

    .initial-content-template-description {
      line-height: 18px;
      text-align: center;
      white-space: pre-line;
      color: $fc-main;
    }
  }

  &-body {
    width: 100%;
  }
}
