.airdrop-project-purpose-setting {
  &-header {
    display: flex;
    align-items: center;
    gap: 5px;
    height: 50px;

    &-icon {
      width: 18px;
      object-fit: contain;
    }

    &-title {
      font-size: 14px;
    }
  }
}
