@import "../../styles/variable";

#Dashboard {
  width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  padding: 0 50px;

  .project-container {
    margin-bottom: 40px;
    width: 1100px;
    max-width: 1100px;
    height: 394px;
    border-radius: 16px;
    background-color: #fff;

    .project-content-wrapper {
      display: grid;
      grid-template-rows: repeat(2, 1fr);
      position: relative;
      height: 100%;

      .project-content-button {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 16px;
        right: 16px;
        padding: 8px 14px;
        color: #7f879e;
        font-weight: 500;
        background-color: #fff;
        border: 1px solid #efefef;
        border-radius: 12px;
        z-index: 100;
      }

      .project-background-image {
        position: relative;
        width: 100%;
        height: 197px;
        overflow-y: hidden;
        border-radius: 16px 16px 0 0;

        .project-background-image-container {
          height: fit-content;

          .project-container-image-src {
            position: absolute;
            top: 50%;
            width: 100%;
            object-fit: cover;
          }
        }
      }

      .project-info-wrapper {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
        padding: 69px 24px 24px;
        height: 100%;

        .project-info {
          display: flex;
          flex-direction: column;

          .project-info-name {
            width: 504px;
            margin-bottom: 12px;
            font-size: 24px;
            font-weight: 700;
            line-height: 31px;
            color: #1b2124;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .project-info-description {
            height: 54px;
            overflow-y: auto;
            color: #7f879e;
            font-size: 14px;
            font-weight: 500;
            line-height: 18px;
            word-break: break-all;

            &::-webkit-scrollbar {
              width: 5px;
              background-color: #f5f6f8;
              -webkit-transition: background-color 0.3s;
            }

            &::-webkit-scrollbar-thumb {
              border-radius: 4px;
              background-color: #b7b7b7;
              //transition: all ease-in-out 0.5s;
            }
          }
        }
      }

      .project-detail-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 16px;
        border: 1.5px solid var(--lgrey-02, #efefef);

        .project-detail-card {
          display: flex;
          flex-direction: column;
          gap: 8px;
          padding: 26px 14px;
          width: 166px;

          .project-detail-card-title {
            color: #7f879e;
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
          }

          .project-detail-card-content {
            display: flex;

            .project-detail-card-content-icon {
              margin-right: 8px;
              width: 18px;
              object-fit: contain;
            }

            .project-detail-card-content-text {
              color: #252a3f;
              font-size: 14px;
              font-weight: 500;
              line-height: 18px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }

        .vertical-line {
          margin: 0 5px;
          width: 2px;
          height: 32px;
          background: #f3f3f3;
        }
      }

      .project-represent-image {
        position: absolute;
        top: calc(50% - 45px);
        left: 24px;
        width: 90px;
        height: 90px;
        object-fit: cover;
        border-radius: 100%;
        background-color: #f5f7ff;
      }
    }
  }

  .dashboard {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    width: 100%;

    &-tab {
      display: flex;
      align-items: center;
      gap: 12px;
      width: 100%;

      &-item {
        padding: 8px 14px;
        font-size: 12px;
        font-weight: 500;
        color: #7f879e;
        border-radius: 12px;
        border: 1px solid #efefef;
        background-color: #fff;
        transition: 0.15s ease-in-out;
        cursor: pointer;

        &.selected {
          color: #f9f9f9;
          border: 1px solid $color-primary;
          background-color: $color-primary;
        }
      }
    }
  }
}
