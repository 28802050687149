.empty-data-template {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 288px;
  height: 100%;
  border-radius: 16px;

  .empty-data-template-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;

    .empty-data-template-image {
      width: 150px;
      object-fit: contain;
    }

    .empty-data-template-text {
      color: #7d828d;
      text-align: center;
      font-size: 14px;
      font-weight: 500;
      white-space: pre-wrap;
    }
  }
}
