@import "../../../../styles/variable";

.processing-notice {
  padding: 24px;
  background-color: #fff;
  border-radius: 16px;

  &-header {
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    &-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 14px;
      width: 34px;
      height: 34px;
      border-radius: 100%;
      background-color: #f9f9f9;

      &-src {
        width: 24px;
        object-fit: contain;
      }
    }

    &-info {
      &-title {
        margin-bottom: 4px;
        font-size: 20px;
        color: $fc-main;
        font-weight: 700;
        line-height: 1.3;
      }

      &-description {
        font-size: 14px;
        line-height: 1.6;
        white-space: pre-line;
      }
    }
  }

  &-tx-hash-container {
    padding: 14px 28px;
    border-radius: 8px;
    background-color: #f9f9f9;

    .processing-notice-tx-hash {
      color: $color-primary;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.3;

      &-key {
      }

      &-value {
        &.active {
          cursor: pointer;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}

.bundle-manager {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 3px;
  width: 100%;
  background-color: #efefef;
  border-radius: 16px;

  &-item {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    height: 50px;
    font-weight: 500;
    background-color: transparent;
    transition: 0.15s linear;
    cursor: pointer;

    &.selected {
      border-radius: 14px;
      background-color: #fff;
      color: $color-primary;
    }
  }
}
