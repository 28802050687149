.sign-up {
  &-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 48px;
    padding: 100px 0 0;
    width: 100%;
    height: 100%;
  }

  &-logo {
    height: 30px;
    object-fit: contain;
  }

  &-body {
    &-password-condition {
      margin-bottom: 40px;
    }

    &-name {
      display: flex;
      gap: 10px;
      margin-bottom: 40px;

      &-item {
        flex: 1;
      }
    }
  }

  &-button-container {
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }
}
