@import "../../../styles/variable";

#ProjectMember {
  width: 1200px;
  margin: 0 auto;
  padding: 0 50px;

  .project-member-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    .project-member-header {
      display: flex;
      justify-content: space-between;
      padding: 24px;
      width: 100%;
      background-color: #ffffff;
      border-radius: 16px;

      &-left {
        display: flex;
        flex-direction: column;
        &-title {
          margin-bottom: 12px;
          font-size: 24px;
          font-weight: 700;
          line-height: 31px;
          color: #1b2124;
        }
        &-description {
          margin-bottom: 12px;
          font-size: 14px;
          font-weight: 500;
          line-height: 18px;
          color: #7f879e;
        }
        &-button {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 200px;
          height: 40px;
          border-radius: 8px;
          background-color: $color-primary;
          font-size: 14px;
          font-weight: 500;
          line-height: 18px;
          color: #ffffff;
          cursor: pointer;
        }
      }
      &-right {
        display: flex;
        align-items: center;
        height: 100px;
        margin: auto 0;
        border-radius: 16px;
        border: 1.5px solid #efefef;
        &-item {
          display: flex;
          align-items: center;
          width: 181.5px;
          padding-left: 19px;
          &-label {
            display: flex;
            align-items: center;
            font-size: 14px;
            line-height: 22px;
            color: #7f879e;
          }
          &-value {
            display: flex;
            align-items: center;
            margin-top: 8px;
            font-size: 14px;
            font-weight: 500;
            line-height: 18px;
            color: #252a3f;
            &-image {
              width: 18px;
              margin-right: 8px;
              object-fit: contain;
            }
          }
          &-divide-line {
            width: 2px;
            height: 32px;
            background-color: #f3f3f3;
          }
        }
      }
    }
    .project-member-user {
      width: 100%;
      &-header {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 0.16fr;
        &-item {
          padding: 10px;
          border-bottom: 1px solid #b1b1c1;
          font-size: 12px;
          font-weight: 500;
          line-height: 16px;
          color: #7f879e;
        }
      }
      &-list {
        max-height: 395px;
        overflow-y: auto;

        &::-webkit-scrollbar {
          border-radius: 10px;
          background: rgba(88, 136, 255, 0.15);
          width: 5px;
          height: 8px;
          background-color: #f5f6f8;
          -webkit-transition: background-color 0.3s;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 4px;
          background-color: #b7b7b7;
        }
      }
      &-row {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 0.16fr;
        width: 100%;
        border-bottom: 1px solid #efefef;
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
        color: #252a3f;

        &-item {
          display: flex;
          align-items: center;
          padding: 4px 10px;
        }
      }
    }
    .project-member-invite {
      width: 100%;
      &-header {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 0.27fr;
        &-item {
          padding: 10px;
          border-bottom: 1px solid #b1b1c1;
          font-size: 12px;
          font-weight: 500;
          line-height: 16px;
          color: #7f879e;
        }
      }
      &-list {
        max-height: 395px;
        overflow-y: auto;

        &::-webkit-scrollbar {
          border-radius: 10px;
          background: rgba(88, 136, 255, 0.15);
          width: 5px;
          height: 8px;
          background-color: #f5f6f8;
          -webkit-transition: background-color 0.3s;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 4px;
          background-color: #b7b7b7;
        }
      }
      &-row {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 0.27fr;
        width: 100%;
        border-bottom: 1px solid #efefef;
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
        color: #252a3f;

        &-item {
          display: flex;
          align-items: center;
          padding: 4px 10px;
        }
      }
      &-list-emthy {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
        color: #7d828d;
        &-image {
          width: 88px;
          object-fit: contain;
          margin-bottom: 20px;
        }
      }
    }
    .member-list-remove-button-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 32px;
      height: 32px;
      border: 1px solid #cf7171;
      border-radius: 8px;
      cursor: pointer;
    }

    .invited-list-cancel-button-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 66px;
      height: 28px;
      border-radius: 10px;
      background-color: $color-primary;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      color: #ffffff;
      cursor: pointer;
    }
  }

  .new-member-information {
    display: flex;
    align-items: center;
    &-profile {
      display: flex;
      align-items: center;
      margin-right: 12px;
      border-radius: 100%;
      &-image {
        width: 20px;
        object-fit: contain;
      }
    }

    &-name {
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;
      color: #000;
    }

    &-its-me {
      margin-left: 12px;
      padding: 0 8px 3px;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      color: #fff;
      background-color: $color-primary;
      border-radius: 8px;
    }
  }
}
