.table-status {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  width: fit-content;
  padding: 5px 10px;
  border-radius: 50px;

  .table-status-icon {
    width: 14px;
    height: 14px;
    object-fit: contain;
  }

  .table-status-text {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #FFFFFF;
  }

  &.Ready {
    background-color: #747474;
  }

  &.Rejected {
    background-color: #CF7171;
  }

  &.Created {
    background-color: #80A3FC;
  }

  &.Submitted, &.Processing, &.Requested {
    background-color: #F4C983;
  }

  &.Prepared {
    background-color: #6BC7E4;
  }

  &.Deployed {
    background-color: #6BC97E;
  }
}
