@import "../../../styles/variable";
@import "../../../styles/mixins";

.field-container {
  margin-bottom: 40px;
  padding: 24px;
  border-radius: 16px;
  background-color: #fff;

  &:last-child {
    margin-bottom: 0;
  }

  .field-header {
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-bottom: 40px;

    &-info-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &-info {
      display: flex;
      align-items: center;
      gap: 10px;
      min-height: 26px;

      &-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 26px;
        height: 26px;
        border-radius: 100%;
        background-color: #e4e6eb;

        &-src {
          width: 18px;
          object-fit: contain;
        }
      }

      &-title {
        font-size: 16px;
        color: #252a3f;
        font-weight: 700;
      }

      &-announcement {
      }
    }

    &-description {
      font-size: 14px;
      color: #6e737d;
      line-height: 18px;
      white-space: pre-line;
    }
  }
}

.field {
  display: flex;
  margin-bottom: 40px;
  width: 100%;

  &:last-child {
    margin-bottom: 0;
  }

  &.row {
    flex-direction: row;
    gap: 10px;
    padding-left: 10px;

    .field-label {
      max-width: 260px;
      min-width: 260px;
      width: 260px;
    }
  }

  &.column {
    flex-direction: column;
    padding-left: 10px;

    .field-label {
      margin-bottom: 10px;
    }
  }
}

.field-label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;

  .field-label-info {
    display: flex;
    align-items: center;

    .field-label-info-text {
      font-size: 16px;
      font-weight: 500;
      color: $fc-sub;
    }

    .field-label-info-btn{
      width: 16px;
      height: 16px;
      margin-left: 5px;
      &.open{
        rotate: 180deg;
      }
      img{
        width: 100%;
      }
    }

    .field-label-info-essential {
      color: #e60019;
    }

    .field-label-info-announcement {
      margin-left: 6px;
    }
  }

  &-description {
    margin-top: 6px;
    font-size: 12px;
    color: $fc-sub;
  }
}
