@import "../../styles/variable";

.dashboard-contract-row-label {
  width: 272px;
  color: $fc-main;
  font-size: 16px;
  font-weight: 400;
}

.dashboard-contract-contract-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.flex-column-20 {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.flex-20 {
  display: flex;
  align-items: center;
  gap: 20px;
}
