@import "../../../../../styles/variable";
@import "../../../../../styles/_mixins";

.airdrop-condition-event-list {
  @include custom-scroll;
  width: 100%;
  max-height: 540px;
  overflow-y: auto;
  padding-right: 5px;

  &-add {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
    border: 2px dashed $color-border-liner;
    border-radius: 8px;
    cursor: pointer;

    &-icon {
      width: 16px;
      object-fit: contain;
    }
  }
}
