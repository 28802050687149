@import "../../../../view/styles/variable";

.status-detail {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 0;
  width: 100%;
  min-height: 124px;
  height: fit-content;
  margin-bottom: 20px;
  border: 1px solid;
  border-radius: 16px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05), 0 2px 5px rgba(61, 84, 128, 0.1);
  text-align: center;

  &-title {
    display: flex;
    line-height: 28px;
    margin-bottom: 5px;

    &-icon {
      width: 20px;
      margin-right: 5px;
      object-fit: contain;
    }

    &-text {
      font-weight: 500;
      font-size: 22px;
    }
  }

  &-description {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    width: 600px;
    overflow-wrap: break-word;
    white-space: pre-wrap;
  }
}
