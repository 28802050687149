@import "../../../../../styles/variable";
@import "../../../../../styles/mixins";

.node-allocation-button-wrapper {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.node-allocation-table-distribution {
  &-percent {
    color: $fc-main;
  }

  &-amount {
    font-size: 14px;
    color: #6e737d;
    word-break: break-all;
    white-space: pre-line;
  }
}

.node-setting-distribute-icon {
  margin-left: 5px;
  width: 16px;
  object-fit: contain;
}

.node-alloc-keystore-button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 14px 0;
  width: 240px;
  max-width: 240px;
  height: 49px;
  border: 1px dashed #b7babe;
  border-radius: 8px;
  cursor: pointer;

  &-address {
    color: $fc-main;
  }

  &-delete {
    width: 16px;
    object-fit: contain;
  }

  &-icon {
    width: 25px;
    object-fit: contain;
  }

  &-label {
    color: #b1b1c1;
  }
}

.node-allocation-table {
  box-sizing: border-box;
  width: 100%;
  border-collapse: separate !important;
  font-size: $fs-normal;
  color: $fc-main;
  border-radius: 20px;
  box-shadow: (0px 1px 1px rgba(0, 0, 0, 0.05)), (0px 2px 5px rgba(61, 84, 128, 0.1));
  table-layout: fixed;
  background-color: $color-white;

  thead {
    tr {
      color: #33373b;
      background-color: #f4f6f8;
      height: 55px;

      th {
        vertical-align: middle;
        cursor: pointer;

        &:first-child {
          border-top-left-radius: 20px;
        }

        &:last-child {
          border-top-right-radius: 20px;
        }
      }
    }
  }

  tbody {
    display: block;
    width: 752px;
    letter-spacing: -0.32px;
    font-weight: normal;

    tr {
      display: table;
      width: 100%;
      height: 109px;

      &:last-child {
        td {
          &:first-child {
            border-bottom-left-radius: 20px;
          }

          &:last-child {
            border-bottom-right-radius: 20px;
          }
        }
      }

      td {
        padding: 0 11px;
        text-align: center;
        vertical-align: middle;
        border-bottom: 1px solid $color-border-liner;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        &:first-child {
          min-width: 150.4px;
          max-width: 150.4px;
          width: 150.4px;
        }

        &:nth-child(2) {
          min-width: 338.4px;
          max-width: 338.4px;
          width: 338.4px;
        }

        &:nth-child(3) {
          min-width: 263.2px;
          max-width: 263.2px;
          width: 263.2px;
        }
      }

      &:first-child {
        td {
          border-top: 1px solid $color-border-liner;
        }
      }

      &:last-child {
        td {
          border-bottom: none;
        }
      }
    }
  }

  .table-no-data {
    cursor: default;
    color: $fc-main;
    font-weight: $fw-medium;
    font-size: $fs-14;
  }
}

.network-equilibrium-node-alloc-field-notice {
  display: flex;
  align-items: flex-start;
  gap: 4px;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #7f879e;

  &-description {
    white-space: pre-line;
  }
}
