@import "../../../view/styles/variable";

.radio-button {
  display: flex;
  align-items: center;
  height: fit-content;

  input[type="radio"] {
    display: none;

    &:checked + label:before {
      border-color: $color-border-liner;
    }

    &:checked + label:after {
      transform: scale(1);
    }

    &:disabled + label {
      cursor: default;
    }

    &:disabled + label::after {
      filter: grayscale(1);
    }
  }

  label {
    display: inline-block;
    height: 20px;
    position: relative;
    padding: 0 45px 0 25px;
    margin-bottom: 0;
    cursor: pointer;
    vertical-align: bottom;

    &:before,
    &:after {
      position: absolute;
      content: "";
      border-radius: 50%;
      transition: all 0.2s ease;
      box-sizing: border-box;
      transition-property: transform;
      background-color: $color-white;
    }

    &:before {
      left: 0;
      top: 0;
      width: 20px;
      height: 20px;
      border: 2px solid $color-border-liner;
    }

    &:after {
      top: 3px;
      left: 3px;
      width: 14px;
      height: 14px;
      transform: scale(0);
      background: $color-blue;
    }
  }

  &.disabled {
    label {
      cursor: not-allowed;

      &:before {
        border-color: #D8D8D8;
        background-color: #ececec;

      }
    }
  }
}
