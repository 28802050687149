.paging-button-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .paging-balance {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 18.2px;
  }

  .paging-button-wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .paging-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 83px;
    height: 34px;
    border-radius: 8px;
    border: 1px solid #EFEFEF;

    font-size: 14px;
    font-weight: 500;
    line-height: 18px;

    &:hover {
      background-color: #F5F5F5;
    }

    &:disabled {
      background-color: #d8d8d8;
      cursor: default;
      color: #f5f5f5;
      border: none;
    }
  }
}