@import "../../styles/variable";
@import "../../styles/mixins";

#DashboardNetwork {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;

  .dashboard-network-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;

    // ANCHOR 사용 안함 
    // .dashboard-network-scroll {
    //   display: flex;
    //   flex-direction: column;
    //   width: 100%;
    //   gap: 20px;
    // }

    .dashboard-network-selector-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      gap: 10px;

      .dashboard-network-selector-label {
        color: #7D828D;
        font-size: 16px;
        font-weight: 500;
      }

      // TODO 추후 ProjectSelector와 병합 or 컴포넌트 분리 필요
      .dashboard-network-selector {
        --item-padding: 11px 15px;
        --item-border: 1px solid #f5f6f8;

        position: relative;
        padding: 10px 16px;
        width: 320px;
        height: 41px;
        background-color: #fff;
        border-radius: 30px;
        border: 1px solid #D9D9D9;
        transition: 0.15s ease-in-out;
        cursor: pointer;

        &:hover {
          border: 1px solid $color-primary;
        }

        &.active {
          border: 1px solid $color-primary;
        }

        &.disabled {
          cursor: not-allowed;
          border: 1px solid #E4E6EB;
          background: #EFEFEF;
        }

        &-area {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 100%;

          &-selected-network {
            display: flex;
            align-items: center;
            width: 100%;
            max-width: 268px;

            &-label {
              text-overflow: ellipsis;
              overflow-x: hidden;
              white-space: nowrap;
              padding-bottom: 0.14em;
              color: #3F4248;
              font-size: 16px;
              font-weight: 400;

              &.disabled {
                color: #AAAFBE;
              }
            }
          }

          &-arrow {
            display: flex;
            justify-content: center;
            align-items: center;
            min-width: 16px;
            width: 16px;
            height: 16px;

            &.active {
              transform: rotate(180deg);
            }

            &-icon {
              width: 100%;
              object-fit: contain;

              &.disabled {
                width: 10px;
              }
            }
          }
        }

        &-panel {
          position: absolute;
          padding-top: 6px;
          top: 43px;
          left: 0;
          width: 320px;
          background-color: #fff;
          border: var(--item-border);
          box-shadow: 0px 4px 30px 0px rgba(127, 135, 158, 0.15);
          border-radius: 16px;

          &-header {
            height: 4px;
            border-top-left-radius: 16px;
            border-top-right-radius: 16px;
          }

          &-network {

            &-item {
              position: relative;
              padding: var(--item-padding);
              border-top: var(--item-border);
              background-color: #fff;
              transition: 0.15s ease-in-out;
              cursor: pointer;

              &:last-child {
                border-bottom-left-radius: 16px;
                border-bottom-right-radius: 16px;
              }

              &:hover {
                background-color: #f4f8ff;
              }

              &-label {
                display: flex;
                align-items: center;
                width: 100%;

                &-dot {
                  margin-right: 15px;
                  width: 8px;
                  height: 8px;
                  border-radius: 100%;
                  background-color: $color-primary;
                  opacity: 0.3;

                  &.selected {
                    opacity: 1;
                  }
                }

                &-name {
                  height: 20px;
                  font-size: 16px;
                  font-weight: 500;
                  color: #7f879e;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  white-space: nowrap;
                }
              }
            }
          }

          &-new-network {
            display: flex;
            justify-content: center;
            padding: var(--item-padding);
            border-top: var(--item-border);
            border-bottom-left-radius: 16px;
            border-bottom-right-radius: 16px;
            transition: 0.15s ease-in-out;
            font-size: 14px;
            font-weight: 500;
            color: $color-primary;
            cursor: pointer;

            &:hover {
              background-color: #f4f8ff;
            }
          }
        }
      }
    }

    .no-project-network {
      width: 100%;
      height: 519px;
      border-radius: 16px;
      background: #FFF;

      .no-project-network-text {
        color: #7D828D;
        text-align: center;
        font-size: 18px;
        font-weight: 500;
      }

      .no-project-network-create-network {
        display: flex;
        padding: 13px 0;
        justify-content: center;
        align-items: center;
        width: 280px;
        margin-top: 60px;
        border-radius: 8px;
        background: linear-gradient(92deg, #6773FF 0%, #47A0FF 100%);

        color: #FFF;
        font-size: 16px;
        font-weight: 500;
        cursor: pointer;
      }
    }
  }

  .node-info-vpn-download {
    display: flex;
    align-items: center;
    gap: 4px;
    cursor: pointer;

    &-label {
      color: $color-primary;
      font-size: 14px;
      font-weight: 500;
    }
  }

  .dashboard-be-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    width: 100%;

    .dashboard-be-chart-wrapper {
      width: 100%;

      .daily-txns {
        .dashboard-be-chart-box {
          width: 100%;
          height: 104px;
        }
      }

      .dashboard-be-chart-custom-tooltip {
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        padding: 5px 10px;
        border: 2px solid #7ea3ff;
        border-radius: 8px;
        background-color: #fff;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);

        &-label {
          font-weight: $fw-medium;

          &-date {
            font-size: 12px;
            line-height: 15px;
            color: $fc-sub;
          }

          &-value {
            font-size: 14px;
            line-height: 18px;
            color: $fc-main;
          }
        }
      }
    }

    .dashboard-be-table-wrapper {
      display: grid;
      gap: 20px;
      grid-template-columns: 1fr 1fr;
      width: 100%;

      //.latest-blocks {
      //  height: 390px;
      //}
      //
      //.latest-transactions {
      //  height: 390px;
      //}
    }
  }
}