@import "../../../styles/variable";

.generate-mfa-modal {
  display: flex;
  width: 684px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;

  &-body {
    display: flex;
    flex-direction: column;
    gap: 25px;
    width: 100%;

    .generate-mfa-modal-step {
      &-title {
        color: $fc-main;
        font-weight: 500;
        line-height: 30px;
      }

      &-description {
        padding-left: 20px;
        font-size: 14px;
        line-height: 20px;
        color: #7f879e;
        white-space: pre-line;
      }
    }

    .generate-mfa-modal-content {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 20px 0;
      background-color: #f4f6f8;
      border-radius: 8px;
    }
  }

  &-description {
    padding-left: 20px;
    font-size: 14px;
    line-height: 20px;
    color: #7f879e;
  }
}

.generate-mfa-modal-google-authenticator {
  display: flex;
  align-items: center;
  gap: 200px;

  &-qr {
    width: 98px;
    object-fit: contain;
  }
}

.generate-mfa-modal-register {
  display: flex;
  align-items: center;
  gap: 100px;

  &-qr {
    width: 98px;
    height: 98px;
    border: 4px solid $color-primary;
    border-radius: 4px;
    padding: 4px;

    &-img {
      width: 100%;
      height: 100%;
    }
  }

  &-key {
    display: flex;
    align-items: center;
    gap: 10px;

    &-value {
      font-size: 18px;
      line-height: 30px;
      color: #7f879e;
    }

    &-copy {
      width: 25px;
      cursor: pointer;

      &-icon {
        width: 100%;
        object-fit: contain;
      }
    }
  }
}

.generate-mfa-modal-otp {
  display: flex;
  align-items: center;
  gap: 20px;

  &-label {
    font-size: 14px;
    line-height: 30px;
    color: #7f879e;
  }

  &-input {
    width: 300px;
  }
}
