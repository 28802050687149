@import "../../../../styles/variable";

.payment-gateway-virtual-account-deposit-date {
  &.date {
    margin-bottom: 6px;
  }
  &.time {
    font-size: 14px;
  }
}

.payment-gateway-virtual-account-deposit-status {
  display: flex;
  justify-content: center;

  &-label {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;

    &-icon {
      margin-left: 4px;
      width: 19px;
      object-fit: contain;
    }

    &.not-confirmed {
      color: #ff2e2e;
    }

    &.inprogress {
      color: #f90;
    }

    &.retry {
      color: #3860e2;
    }

    &.success {
      color: #00da7d;
    }
  }
}

.deposit-status {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 18px;
  cursor: pointer;
  width: 100px;
  height: 36px;
  &.resend {
    border: solid 1px $color-primary;
    background-color: $color-white;
    color: $color-primary;
  }
  &.retry {
    border: solid 1px #de1f1f;
    background-color: $color-white;
    color: #de1f1f;
  }
}

.virtual-address {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;

  .address {
    position: relative;
    &:hover {
      &::after {
        position: absolute;
        top: -20px;
        left: 50%;
        border: 2px solid #7ea3ff;
        border-radius: 8px;
        background-color: #fff;
        box-shadow: 0 2px 5px #3d548025;
        transform: translateX(-50%);
        font-weight: 500;
        color: $fc-main;
        content: attr(data-virtual-address);
        padding: 5px 10px;
        width: fit-content;
      }
    }
  }

  .copy-icon-wrapper {
    background-color: transparent;
  }
  .copy-icon {
    width: 20px;
    object-fit: contain;
  }
}

.payment-gateway-virtual-account-deposit-filter-button-container {
  display: flex;
  justify-content: flex-end;
}
