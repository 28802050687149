@import "../../../../../styles/variable";

.keystore-file-setting-modal {
  padding: 40px 72.5px 0;

  &-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 420px;
    max-width: 420px;

    .keystore-file-setting-modal-row {
      display: flex;
      flex-direction: column;
      gap: 10px;

      &-title {
        display: flex;
        align-items: center;
        gap: 10px;

        &-dot {
          width: 5px;
          height: 5px;
          background-color: #b1b1c1;
        }

        &-label {
          font-weight: 500;
          color: #848a96;
        }

        &-new-key {
          font-weight: 500;
          font-size: 14px;
          color: $color-primary;
          text-decoration: underline;
          cursor: pointer;
        }
      }

      &-error {
        height: 18px;
        font-size: 14px;
        font-weight: 500;
        color: $color-red;
      }
    }
  }

  &-button-wrapper {
    display: flex;
    justify-content: center;
  }
}
