.status{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  width: fit-content;
  &-icon{
    width: 20px;
    height: 20px;
    object-fit: contain;
  }
  &-text{
    font-size: 14px;
    font-weight: 500;
    line-height: 130%; /* 18.2px */
    white-space: nowrap;
    color: #1B2124;
  }
}
