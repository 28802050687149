@import "../../../styles/variable";

.modal-template-B {
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  background-color: #ffffff;
  padding: 40px;

  .modal-template-B-header {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 40px;
    position: relative;

    .modal-template-B-header-close {
      position: absolute;
      top: 0;
      right: 0;
      width: 16px;
      height: 16px;
      object-fit: contain;
      cursor: pointer;
    }

    &-title {
      font-size: 32px;
      font-weight: 500;
      line-height: 37px;
      text-align: center;
      color: $fc-main;
    }

    &-description {
      font-size: 16px;
      font-weight: 400;
      line-height: 18px;
      text-align: center;
      white-space: pre-wrap;
      word-break: break-all;
      color: $fc-sub;
    }
  }

  .modal-template-B-content {
    position: relative;
  }
}
