@import "../../../styles/variable";

.comment-input {
  position: relative;
  display: flex;
  width: 100%;

  .comment-input-text {
    position: absolute;
    top: calc($input-height + 3px);
    left: 5px;
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    white-space: pre;
  }
}
