@import '../../../../styles/variable';

.nft-card {
  display: flex;
  flex-direction: column;
  width: 240px;
  min-height: 296px;
  max-height: 350px;
  padding: 15px;
  background: $color-white;
  border: 2px solid #ECECEC;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05), 0 2px 5px rgba(61, 84, 128, 0.1);
  border-radius: 18px;
  cursor: pointer;
  transition: all 0.2s ease;
  overflow: hidden;
  text-overflow: ellipsis;
  &:hover{
    border: 2px solid #8EAFFF;
  }
  .nft-image-container{
    display: flex;
    justify-content: center;
    width: 100%;

    .nft-content {
      width: 206px;
      height: 206px;
      border-radius: 8px;
      object-fit: cover;
    }
  }


  .nft-detail-wrapper {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;

    .nft-token-id {
      font-weight: 400;
      font-size: 16px;
      color: $fc-sub;
    }

    .nft-token-title {
      width: 100%;
      height: 80px;
      overflow: hidden;
      word-break: break-word;
      font-weight: 500;
      font-size: $fs-normal;
      color: $fc-main;
    }
  }
}
