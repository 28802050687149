.notifications {
  position: relative;
  display: flex;
  padding: 11px;
  border-radius: 6px;
  cursor: pointer;

  &:hover {
    background-color: #f9f9f9;
  }

  .notifications-icon {
    display: flex;
    justify-content: center;
    align-items: center;

    &-src {
      width: 20px;
      object-fit: contain;
    }
  }
}
