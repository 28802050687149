.get-proxy-contract-notice-box {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
  height: 108px;
  background: #f4f6f8;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05), 0 2px 5px rgba(61, 84, 128, 0.1);
  border-radius: 16px;
}
.get-proxy{
    .field-label{
    margin-bottom: 10px;
  }
  .contract-info{
    margin-bottom: 20px;
  }
}
