@use "sass:map";
@import "src/view/styles/variable";

.EQBR_paging-numbers {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7px;

  .EQBR_paging-number {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    font-weight: 500;
    font-size: 14px;
    line-height: 160%;
    color: #1C1F21;
    cursor: pointer;
    &.selected {
      color: #5888FF;
    }
  }

  .EQBR_paging-button {
    display: flex;
    justify-content: center;
    align-items: center;
    
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    border-radius: 20px;
  
    cursor: pointer;
    &.disabled{
      opacity: 30%;
      cursor:default;
    }
    img{
      width: 36px;
      height: 36px;
    }
  }
}
