.get-fixed{
  .contract-info{
    margin-bottom: 20px;
  }
  .field-container{
    margin-bottom: 20px;
  }
  .field.row.constructor{
    margin-bottom: 20px;
  }
  .constructor-description{
    width: 100%;
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 8px;
    background-color: #EFF1F6;
    font-size: 14px;
    line-height: 22px;
    color: #3D425A;
  }
  .field-label{
    margin-bottom: 10px;
  }
  .limit{
    border:2px solid #FF1717;
  }
  .limit-text{
    margin-top: 5px;
    font-size: 12px;
    font-weight: 500;
    color: #CF7171;
  }
}