@import "../../../../../styles/variable";

.node-operation-wrapper {
  display: flex;
  align-items: center;
  gap: 5px;

  .node-operation-unit {
    color: $fc-main;
    white-space: nowrap;
  }
}
