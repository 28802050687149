@import "../../../../view/styles/variable";

.file-uploader {
  .preview {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 350px;
    height: 350px;
    border: 2px solid $color-border-liner;
    border-radius: 8px;
    background-color: $color-white;
    color: $fc-placeholder;
    line-height: 18px;
    text-align: center;
    overflow: hidden;
    white-space: pre-wrap;
    cursor: pointer;
    .preview-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .preview-video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .upload-comment {
    display: flex;
    align-items: center;
    height: 30px;
    font-weight: $fw-regular;
    font-size: 12px;
    text-align: center;
    color: $fc-placeholder;
  }
  input[type="file"] {
    display: none;
  }
}
