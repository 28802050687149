.vertical-card-container {
  display: flex;
  align-items: center;
  gap: 22px;
  margin-top: 20px;
  width: 256px;
  height: 412px;
  cursor: pointer;

  .vertical-card {
    position: relative;
    width: 256px;
    height: 396px;
    border-radius: 16px;
    overflow: hidden;
    box-shadow: 0.5px 1px 6px 0px #0000001F;
    transition: all 0.1s ease-in;

    &:hover {
      transform: translateY(-5px);
      box-shadow: 0.5px 1px 20px 0px #0000001A;
      .vertical-card-img-contract-box{
        opacity: 100%;
      }
    }

    .vertical-card-img-contract-box{
      opacity: 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: absolute;
      padding: 20px 15px;
      width: 100%;
      height: 256px;
      transition: all 0.1s ease-in;

      .vertical-card-img-contract-types{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 6px;
        .vertical-card-img-contract-type{
          display: flex;
          justify-content: center;
          align-items: center;
          height: 26px;
          padding: 0 10px;
          border: 1px solid #fff;
          border-radius: 17px;

          font-size: 14px;
          font-weight: 500;
          color: #fff;
          white-space: nowrap;
          &.category{
            background-color: #fff;
            color: #616161;
          }
        }
      }
    }
    .vertical-card-img-box {
      width: 100%;
      height: 256px;

      img{
        width: 100%;
        height: 100%;
      }
    }

    .vertical-card-text-box {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 140px;
      padding: 16px 20px 20px;
      background-color: #fff;

      line-height: 18px;

      .card-detail {
        display: flex;
        align-items: center;
        gap: 8px;
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
        color: #7F879E;

        .card-detail-bar {
          width: 1px;
          height: 16px;
          background-color: #D8D8D8;
        }

        .deploy-count {
          display: flex;
          align-items: center;
          gap: 4px;

          .deploy-icon {
            width: 12px;
            height: 12px;
          }
        }
      }

      .card-title {
        margin-top: 6px;
        font-size: 22px;
        font-weight: 700;
        line-height: 28px;
        letter-spacing: -0.03em;
        color: #1D1F22;
      }

      .card-description {
        margin-top: 4px;
        line-height: 24px;
        color: #7F879E;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
}
