@import "../../../../view/styles/variable";


.page-title-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 30px 50px;
  background-color: #f9fafb;
  border-bottom: 1px solid #d9d9d9;
  box-sizing: content-box;

  .page-title {
    display: flex;
    flex-direction: column;

    .page-title-header {
      font-size: 24px;
      font-weight: 700;
      line-height: 31px;
      color: $fc-main;
      margin-bottom: 5px;
    }

    .page-description-wrapper {
      display: flex;
      flex-direction: column;

      .page-description {
        font-size: 16px;
        font-weight: 400;
        line-height: 21px;
      }

      .description-button {
        font-size: 16px;
        font-weight: 500;
        line-height: 21px;
        color: $color-blue;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
