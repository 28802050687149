@import "../../../styles/variable";
@import "../../../styles/_mixins";

.project-requests {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 357px;
  max-height: 357px;

  .project-requests-chart {
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
    color: $fc-sub;
    height: 244px;

    &-custom-tooltip {
      border: 2px solid $color-blue;
      border-radius: 8px;
      background-color: #fff;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
      box-sizing: border-box;
      padding: 2px 8px;

      &-label {
        font-size: $fw-medium;
        font-size: 13px;
        line-height: 17px;
        color: $fc-main;
      }
    }
  }

  .total-request {
    display: flex;
    align-items: end;
    font-size: 24px;
    font-weight: 700;
    color: $color-primary;

    &-unit {
      margin-left: 5px;
      font-weight: 500;
      font-size: 16px;
    }
  }

  &-options {
    display: flex;
    align-items: center;
    gap: 12px;
    width: 100%;

    &-item {
      padding: 8px 14px;
      font-size: 12px;
      font-weight: 500;
      color: #7f879e;
      border-radius: 12px;
      border: 1px solid #efefef;
      background-color: #fff;
      transition: 0.15s ease-in-out;
      cursor: pointer;

      &.selected {
        color: #f9f9f9;
        border: 1px solid $color-primary;
        background-color: $color-primary;
      }
    }
  }

  &-type-selector {
    position: relative;
    padding: 5px 16px;
    background-color: #f9f9f9;
    border-radius: 16px;
    cursor: pointer;

    &-area {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &-label {
        width: 96px;
        font-size: 14px;
        font-weight: 500;
        color: #1b2124;
      }

      &-icon {
        width: 16px;
        height: 16px;

        &.active {
          transform: rotate(180deg);
        }

        &-src {
          width: 100%;
          object-fit: contain;
        }
      }
    }

    &-panel {
      position: absolute;
      top: 26px;
      left: 0;
      padding-top: 4px;
      width: 100%;

      &-list {
        border-radius: 16px;
        box-shadow: 0px 4px 30px 0px rgba(127, 135, 158, 0.15);

        &-item {
          padding: 7px 16px;
          font-size: 14px;
          font-weight: 500;
          color: #1b2124;
          background-color: #fff;

          &:hover {
            background-color: #f9f9f9;
          }
          &:first-child {
            border-top-left-radius: 16px;
            border-top-right-radius: 16px;
          }

          &:last-child {
            border-bottom-left-radius: 16px;
            border-bottom-right-radius: 16px;
          }
        }
      }
    }
  }
}
