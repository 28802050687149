@import "../../../../../styles/variable";
@import "../../../../../styles/mixins";

.node-preset-selector {
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 48px;
    background-color: $color-blue;
    border-radius: 16px;

    &-area {
      display: grid;
      grid-template-columns: repeat(3, 115px);
      grid-gap: 5px;

      &-item {
        display: flex;
        align-items: center;

        &-icon {
          margin-right: 5px;
          width: 20px;
          height: 20px;

          &-src {
            width: 100%;
            object-fit: contain;
          }
        }

        &-label {
          color: #fff;
          font-size: 12px;
          font-weight: 500;
        }
      }
    }
  }

  &-body {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(1, 100%);
    padding: 10px 0;
  }

  &-cost {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 48px;
    border: 1px solid #b0c2fe;
    border-radius: 16px;

    &-label {
      font-size: 16px;
      font-weight: 500;
      color: $fc-main;
    }

    &-info {
      display: flex;
      align-items: center;

      &-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 5px;
        width: 30px;
        height: 30px;
        border-radius: 100%;
        border: 1px solid #e7e9ef;

        &-src {
          width: 19.5px;
          object-fit: contain;
        }
      }

      &-value {
        margin-right: 10px;
        font-size: 24px;
        font-weight: 500;
        color: $color-blue;
      }
    }
  }
}
