@import "../../../styles/variable";

.verify-email {
  &-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 48px;
    padding: 150px 0 0;
    width: 100%;
    height: 100%;
  }

  &-logo {
    height: 30px;
    object-fit: contain;
  }

  &-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    &-description {
      text-align: center;
      line-height: 18px;
      color: $fc-main;

      &-email {
        text-decoration: underline;
        color: $color-primary;
      }
    }

    &-receive {
      &-trigger {
        color: $color-primary;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  &-button-container {
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }
}
