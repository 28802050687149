.initial-notice-template {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  min-width: 550px;
  border-radius: 20px;
  background-color: #fff;

  &-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .initial-notice-template-image {
      margin-bottom: 40px;

      &-src {
        height: 80px;
        object-fit: contain;
      }
    }

    .initial-notice-template-title {
      margin-bottom: 20px;
      font-weight: 500;
      font-size: 32px;
      line-height: 38px;
    }
  }

  &-body {
    width: 100%;
  }
}
