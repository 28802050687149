@import "../../../styles/variable";

.dashboard-api-card-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: 210px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    border-radius: 10px;
    background: rgba(88, 136, 255, 0.15);
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar {
    width: 5px;
    background-color: #f5f6f8;
    -webkit-transition: background-color 0.3s;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #b7b7b7;
  }

  &-item {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 12px 24px;
    min-height: 77px;
    border-radius: 16px;
    border: 1px solid #efefef;
    overflow: hidden;

    .dashboard-api-card-list-item-label-wrapper {
      display: flex;
      align-items: center;
      height: 21px;

      .dashboard-api-card-list-item-icon {
        margin-right: 6px;
        width: 16px;
        height: 16px;
        object-fit: cover;
        border-radius: 100%;
      }

      .dashboard-api-card-list-item-label {
        color: $color-primary;
        font-size: 14px;
        font-weight: 700;
        line-height: 18px;
      }

      .dashboard-api-card-list-item-info {
        margin-left: 10px;
      }
    }

    .dashboard-api-copy-label-with-icon {
      position: relative;
      display: flex;
      align-items: center;

      .dashboard-api-copy-label {
        max-width: 414px;
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .dashboard-api-copy-icon-wrapper {
        position: relative;
        display: flex;
        align-items: center;
        margin-left: 3px;
        width: 20px;
        background-color: transparent;
        cursor: pointer;

        &::after {
          content: "Copied!";
          position: absolute;
          top: 30px;
          right: -16px;
          padding: 5px 10px;
          color: $fc-main;
          font-size: 14px;
          font-weight: 500;
          border: 2px solid #7ea3ff;
          border-radius: 8px;
          background-color: #fff;
          box-shadow: 0px 2px 5px 0px rgba(61, 84, 128, 0.25);
          opacity: 0;
          transition: 0.15s ease-in-out;
        }

        &.clicked {
          &::after {
            top: -30px;
            opacity: 1;
          }
        }

        .dashboard-api-copy-icon {
          width: 100%;
          object-fit: contain;
        }
      }
    }
  }
}
