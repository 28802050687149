@import "../../../../../styles/variable";

.airdrop-condition-event-box {
  display: flex;
  flex-direction: column;
  padding: 20px;
  background: $color-white;
  border: 1px solid $color-border-liner;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05), 0 2px 5px rgba(61, 84, 128, 0.1);
  border-radius: 8px;
  width: 100%;

  &-wrapper {
    margin: 0 0 10px;

    .airdrop-condition-event-box-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      // margin: 0 0 5px;

      .airdrop-condition-event-box-label {
        margin-bottom: 5px;
        font-size: $fs-comment;
        color: $fc-sub;
      }

      .airdrop-condition-event-box-delete {
        width: 10px;
        cursor: pointer;

        &-icon {
          width: 100%;
          object-fit: contain;
        }
      }
    }

    &:last-child {
      margin: 0;
    }
  }
}
