footer {
  width: 100%;
  padding: 20px 50px;
  //min-width: 1240px;
  background-color: #E4E6EB;
  color: #7F879E;
  font-weight: 500;
  font-size: 16px !important;
  line-height: 21px;
  text-align: left;
}
