@import "../../styles/variable";

.main-header {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  background-color: #fff;
  z-index: 999;
  width: 100%;
  height: $header-height;

  .main-header-logo {
    padding-left: 20px;

    .main-header-menu {
      width: 24px;
      height: 24px;
      object-fit: contain;
      margin-right: 20px;
      cursor: pointer;
    }

    .main-header-logo-src {
      cursor: pointer;
      height: 24px;
      object-fit: contain;
    }
  }

  .main-header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    padding: 0 20px;

    .gnb {
      display: flex;
      align-items: center;
      gap: 40px;
      padding: 0 10px;

      .gnb-item {
        font-size: 14px;
        font-weight: 500;
        line-height: 30px;
        cursor: pointer;
      }
    }

    .user-menu {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 13px 6px;

      .user-menu-info {
        display: flex;
        width: 200px;
        position: relative;
        align-items: center;
        padding: 9px 7px;
        border-radius: 6px;
        transition: 0.15s ease-in-out;
        cursor: pointer;

        &:hover {
          background-color: #f9f9f9;
        }

        .user-menu-info-icon {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 10px;
          min-width: 24px;
          max-width: 24px;
          min-height: 24px;
          max-height: 24px;
          border-radius: 100%;
          background-color: #e4e6eb;

          .user-menu-info-icon-src {
            width: 18px;
            object-fit: contain;
          }
        }

        .user-menu-info-label-wrapper {
          position: relative;
          width: 100%;
          height: 24px;

          .user-menu-info-label {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            font-weight: 500;
            line-height: 1.3;

            .user-menu-info-label-hello {
              color: #aaafbe;
            }

            .user-menu-info-label-name {
              color: $fc-main;
            }
          }
        }
      }
    }
  }

  .main-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;

    .nav-logo {
      cursor: pointer;
      height: 25px;
      object-fit: contain;
    }
  }
}
