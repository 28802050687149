@import "../../../styles/variable";

.faq-toggle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 800px;
  height: 78px;
  padding: 20px;
  border: 2px solid #f0f0f0;
  border-radius: 8px;
  background: $color-white;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05),
    0px 2px 5px rgba(61, 84, 128, 0.1);
  transition: border ease 0.07s;
  cursor: pointer;
  .faq-toggle-title {
    display: flex;
    justify-content: space-between;
    .faq-toggle-title__question {
      display: flex;
      align-items: center;
      width: 100%;
      font-weight: 500;
      font-size: 22px;
      line-height: 28px;
      white-space: pre-wrap;
    }
    .faq-toggle-title__button {
      width: 10px;
      object-fit: contain;
    }
  }
  .faq-toggle__answer {
    display: none;
  }
  &:hover {
    border: 2px solid #8eafff;
  }
  &.true {
    display: flex;
    justify-content: flex-start;
    height: 540px;
    .faq-toggle-title {
      margin: 3px 0 25px;
      padding-bottom: 25px;
      border-bottom: 2px solid #d7e2ff;
      .faq-toggle-title__button {
        transform: rotate(180deg);
      }
    }
    .faq-toggle__answer {
      display: flex;
    }
  }
}
