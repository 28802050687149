@import "../../../../styles/variable";

.manage-nft-content-wrapper {
  display: flex;

  .nft-content {
    width: 350px;
    height: 350px;
    object-fit: cover;
    border-radius: 8px;
  }
}
.nft-audio-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  .nft-audio-preview {
    width: 350px;
    height: 350px;
    margin-bottom: 20px;
    object-fit: cover;
    border-radius: 8px;
  }
  .nft-audio {
    width: 350px;
  }
}

.manage-nft-detail-wrapper {
  display: flex;
  flex-direction: column;
  .nft-title-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .nft-title {
      word-break: break-word;
      margin-bottom: 10px;
      font-weight: $fw-medium;
      font-size: 24px;
      color: $fc-main;
    }
    .nft-description {
      word-break: break-word;
      font-weight: $fw-regular;
      font-size: $fs-normal;
      line-height: 21px;
      color: $fc-main;
    }
  }
}

.manage-nft-detail-row {
  display: flex;
  flex-direction: row;

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  .manage-nft-detail-index {
    width: 200px;
    margin-right: 20px;
    font-weight: 500;
    font-size: 16px;
    color: $fc-sub;
  }

  .manage-nft-detail-data {
    flex: 1;
    word-break: break-word;
    font-weight: 400;
    font-size: 16px;
    color: $fc-main;
  }
}
