@import "../../../../styles/variable";

.email-verification-result {
  display: flex;
  flex-direction: column;
  align-items: center;
  .email-verification-icon {
    width: 80px;
    margin-bottom: 40px;
    object-fit: contain;
  }
  .email-verification-content {
    margin-bottom: 40px;
    font-size: 16px;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #222;
    white-space: pre-wrap;
  }
  .email-verification-done-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 280px;
    height: 48px;
    border-radius: 8px;
    background-color: $color-blue;
    font-size: 16px;
    color: $color-white;
  }
}

.email-verification {
  &-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 48px;
    padding: 150px 0 0;
    width: 100%;
    height: 100%;
  }

  &-logo {
    height: 30px;
    object-fit: contain;
  }

  &-content {
    &-description {
      line-height: 18px;
      text-align: center;
      white-space: pre-line;
    }
  }

  &-button-container {
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }
}
