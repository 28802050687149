@import "src/view/styles/variable";

.dashboard-method-request-template {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 740px;

  &-content {
    display: flex;
    flex-direction: column;
    height: 580px;
  }

  &-header {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    &-name {
      color: #252a3f;
      font-size: 16px;
      font-weight: 700;
      line-height: 130%;
      text-transform: capitalize;
      margin-bottom: 5px;
    }

    &-description {
      color: $fc-main;
      font-size: 14px;
      font-weight: 400;
      line-height: normal;
    }
  }

  &-inputs {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
    margin-top: 20px;
    padding-right: 10px;

    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
      background: rgba(88, 136, 255, 0.15);
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: $color-blue;
    }
  }

  &-result {
    display: flex;
    flex-direction: column;

    &-title {
      font-size: $fs-index;
      color: $fc-main;
      font-weight: $fw-medium;
      line-height: 23px;
    }

    &-data {
      display: flex;
      width: 100%;
      height: 107px;
      margin-top: 10px;
      border-radius: 8px;
      border: 2px solid #e7e9ef;
      padding: 14px 20px;
      overflow: auto;
    }
  }
}

.dashboard-method-input-wrapper {
  &:not(:last-child) {
    margin-bottom: 20px;
  }

  &-name {
    color: $fc-main;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 5px;
  }

  &-description {
    margin-bottom: 10px;
    color: $fc-main;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
