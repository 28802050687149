@import "../../../../view/styles/variable";

.list-type-b-card {
  display: flex;
  width: 100%;
  padding: 20px;
  background: $color-white;
  border: 1px solid $color-border-liner;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05), 0 2px 5px rgba(61, 84, 128, 0.1);
  border-radius: 16px;
  transition: all 0.2s ease;
  cursor: pointer;
  &:hover {
    border: 1px solid #8eafff;
  }
  &:not(:last-child) {
    margin-bottom: 20px;
  }

  .list-card-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 150px;
    // padding: 10px;
    margin-right: 20px;
    background: $color-white;
    border: 2px solid $color-border-liner;
    border-radius: 8px;
    overflow: hidden;

    .list-card-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .list-card-detail-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    .list-card-detail-top-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .list-card-detail-name {
        max-width: 560px;
        line-height: 30px;
        font-weight: $fw-medium;
        font-size: 24px;
        color: $fc-main;
        white-space: pre;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .list-card-detail-bottom-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 1;
      .list-card-detail-row {
        display: flex;
        align-items: center;
        margin-bottom: 5px;
        &:last-child {
          margin-bottom: 0px;
        }
        .list-card-detail-index {
          width: 200px;
          font-weight: $fw-medium;
          font-size: $fs-normal;
          color: $fc-sub;
        }
        .list-card-detail-data {
          font-weight: $fw-regular;
          font-size: $fs-normal;
          line-height: 21px;
          color: $fc-main;
        }
      }
    }
  }
}
