@import "../../../view/styles/variable";
.input {
  position: relative;
  width: 100%;
  flex: 1;
  .atom-input {
    width: 100%;
    height: $input-height;
    padding: 15px 20px;
    border-radius: 8px;
    border: solid 2px $color-border-liner;
    background-color: $color-white;
    font-size: $fs-normal;
    color: $fc-main;
    &::placeholder {
      color: $fc-placeholder;
    }
    &:focus {
      outline: none;
    }
    &:read-only {
      padding: 0;
      outline: none;
      border: none;
      color: $fc-main;
      background-color: transparent;
      cursor: default;
    }
    &:disabled {
      height: fit-content;
      padding: 0;
      outline: none;
      border: none;
      background-color: transparent;
      color: $fc-main;
    }
    &.input-invalid-box {
      border-color: #fd1d1d !important;
    }
  }
  .input-description {
    line-height: 15px;
    font-size: $fs-small;
    font-weight: $fw-medium;
    color: $fc-placeholder;
  }
  .input-invalid-comment {
    position: absolute;
    top: calc($input-height + 2px);
    padding: 0 8px;
    font-size: $fs-comment;
    font-weight: $fw-medium;
    color: #fd1d1d;
  }
}
