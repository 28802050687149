@import "../../../../view/styles/variable";

.card-previewer {
  position: relative;
  width: 350px;
  height: 200px;
  border-radius: 18px;
  border: 1px solid $color-border-liner;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05), 0 2px 5px rgba(61, 84, 128, 0.1);
  overflow: hidden;

  &-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &-content {
    position: absolute;
    top: 0;
    left: 0;
    padding: 20px 20px 20px 15px;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .card-previewer-header {
      padding-left: 14px;
      .card-previewer-network-info {
        display: flex;
        align-items: center;
        margin-bottom: 4px;

        .card-previewer-network-label {
          font-size: $fs-14;
          font-weight: $fw-medium;
          line-height: 21px;
        }
      }

      .card-previewer-address-wrapper {
        display: flex;
        align-items: center;

        .card-previewer-address {
          font-size: $fs-14;
          font-weight: $fw-medium;
          line-height: 18px;
          letter-spacing: -0.3px;
        }

        .card-previewer-copy {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: 5px;
          width: 16px;
          height: 16px;
          border-radius: 3px;
          background-color: $color_white;
          opacity: 0.6;

          &-src {
            width: 10px;
            object-fit: contain;
          }
        }
      }
    }

    .card-previewer-middle {
      .card-previewer-amount {
        font-weight: $fw-bold;
        font-size: $fs-20;
        line-height: 26px;
        letter-spacing: -0.3px;
      }
    }

    .card-previewer-footer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;

      .card-previewer-token-count {
        padding-left: 14px;
        font-size: $fs-14;
        font-weight: $fw-medium;
        line-height: 18px;
        letter-spacing: -0.3px;
      }

      .card-previewer-button-wrapper {
        display: flex;
        flex-direction: row;

        .card-previewer-button {
          width: 40px;
          height: 40px;
          object-fit: contain;

          &:first-child {
            margin-right: 10px;
          }
        }
      }
    }
  }
}
