@import "../../../../styles/variable";

.tool-state-box-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 20px;
  width: 100%;
  gap: 20px;
}

.manage-network-visualization-allocation {
  display: flex;
  justify-content: center;
  align-items: center;

  .chart-label {
    font-size: $fs-small;

    &-name {
      font-weight: $fw-medium;
    }

    &-percent {
      font-size: 10px;
      font-weight: $fw-regular;
    }
  }
}

.manage-network-total-allocated {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  &-label {
    font-weight: 500;
    line-height: 21px;
    color: $fc-sub;
  }

  &-value {
    display: flex;
    align-items: flex-end;
    gap: 3px;
    font-weight: 500;
    color: $fc-main;

    &-amount {
      font-size: 18px;
      line-height: 23px;
    }

    &-symbol {
      font-size: 14px;
      line-height: 18px;
    }
  }
}

.manage-network-visualization-allocation {
  display: flex;
  flex-direction: column;
  align-items: center;

  &-data {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    color: $fc-main;

    &-label {
      font-weight: $fw-regular;
      font-size: $fs-normal;
    }

    &-value {
      font-weight: $fw-medium;
      font-size: $fs-index;

      &-symbol {
        margin: 0 0 0 5px;
        font-size: $fs-comment;
      }
    }
  }
}

.node-data {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0 0;
  width: 100%;
  color: $fc-main;

  &-label {
    font-weight: $fw-regular;
    font-size: $fs-normal;
  }

  &-value {
    font-weight: $fw-medium;
    font-size: $fs-index;

    &-symbol {
      margin: 0 0 0 5px;
      font-size: $fs-comment;
    }
  }
}

.network-button-container {
  margin-top: 40px;
  display: flex;
  justify-content: flex-end;
}
