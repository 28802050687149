#EditProjectModal {
  .project-info-modal-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 680px;
    min-height: 662px;

    &-row {
      display: flex;
      align-items: center;

      &:not(:last-child) {
        margin-bottom: 25px;
      }

      &-label {
        display: flex;
        align-items: center;
        width: 140px;

        &-square {
          background-color: #b1b1c1;
          margin-right: 10px;
          width: 5px;
          height: 5px;
        }

        &-text {
          font-size: 16px;
          font-weight: 500;
          line-height: 21px;
          color: #848a96;
        }
      }

      &-value {
        flex: 1;
      }
    }

    .project-info-modal-button-wrapper {
      display: flex;
      justify-content: center;
      margin-top: 40px;
    }
  }
}
