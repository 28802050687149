.connect-wallet-modal {
  padding: 30px;

  .connect-wallet-modal-header {
    width: 100%;
    margin-bottom: 30px;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    color: #7F879E;
    text-align: center;
  }

  .connect-wallet-modal-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 30px;

    .connect-wallet-modal-content-button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 366px;
      padding: 15px;
      border-radius: 16px;
      background-color: #E5E8EE80;

      transition: all 0.3s ease;
      cursor: pointer;

      &:hover {
        background-color: #C1CBE280;
      }

      &:active {
        background-color: #A5B5DE80;
      }

      .connect-wallet-modal-content-logo {
        height: 42px;
        object-fit: contain;
      }
    }
  }

  .connect-wallet-modal-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    font-weight: 700;
    font-size: 14px;
    line-height: 30px;

    transition: all 0.3s ease;

    color: #8D9092;

    .connect-wallet-modal-footer-link {
      color: #5888FF;
      cursor: pointer;

      &:hover {
        text-decoration: 1px underline;
      }
    }
  }
}
