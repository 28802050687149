@import "../../../../view/styles/variable";

.progressbar {
  position: relative;
  width: 100%;
  height: 10px;

  &-back {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $color-border-liner;
    border-radius: 15px;
  }

  &-front {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background-color: $color-blue;
    border-radius: 15px;

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      right: -6px;
      transform: translateY(-50%);
      width: 10px;
      height: 10px;
      border-radius: 100%;
      background-color: $color-white;
      border: 1px solid $color-border-liner;
    }
  }
}
