@import 'src/view/styles/variable';

.dashboard-method-select-wrapper{
  display: flex;
  flex-direction: column;
  width: 220px;
  height: 360px;
  background-color: $color-white;
  &:not(:last-child){
    margin-bottom: 20px;
  }
  &-header{
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    &-title{
      margin-bottom: 3px;
      color: #252A3F;
      font-size: 16px;
      font-weight: 700;
      line-height: 130%;
      text-transform: capitalize;
    }
    &-description{
      color: #7E7E7E;
      font-size: 14px;
      font-weight: 400;
      line-height: normal;
    }
  }
  &-method-box{
    display: flex;
    flex-direction: column;
    height: 315px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 10px;
    background: $color-white;
    border: 1px solid #ECECEC;
    border-radius: 8px;
    &-row{
      &:not(:last-child){
        margin-bottom: 5px;
      }
      overflow-x: clip;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding: 8px 5px;
      border-radius: 4px;
      cursor: pointer;
      &.selected{
        background-color: rgba(88, 136, 255, 0.1);;
        color: $color-blue;
        font-weight: $fw-bold;
      }
    }
    &::-webkit-scrollbar {
      width: 5px;
      height: 8px;
      background-color: #B3B6BE26;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: #B3B6BE;
    }
  }


}
