@import '../../../../styles/variable';

.library-contract-card {
  display: flex;
  gap: 20px;
  border-radius: 16px;
  background: $color-white;
  box-shadow: 0.5px 1px 6px 0px #0000001F;
  transition: all 0.1s ease-in;
  cursor: pointer;

  width: 100%;
  padding: 20px;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0.5px 1px 20px 0px #0000001A;
  }

  .library-contract-card-img-box {
    width: 130px;
    height: 130px;

    img {
      width: 100%;
      border-radius: 12px;
    }
  }

  .library-contract-card-text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    width: calc(100% - 170px);

    .library-contract-card-text-top-detail {
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
      color: #7F879E;

      .library-contract-card-text-top-detail-bar {
        width: 1px;
        height: 16px;
        background-color: #D8D8D8;
      }

      .library-contract-card-text-top-deploy-count {
        display: flex;
        align-items: center;
        gap: 4px;

        .library-contract-card-text-top-deploy-icon {
          width: 12px;
          height: 12px;
        }
      }
    }


    .library-contract-card-text-top-title {
      margin-top: 4px;
      font-size: 22px;
      font-weight: 700;
      line-height: 28px;
      color: #1D1F22;
    }

    .library-contract-card-text-top-description {
      width: 100%;
      margin-top: 4px;
      line-height: 24px;
      color: #7F879E;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; /* 말줄임 적용 */
    }

    .library-contract-card-text-btm {
      display: flex;
      align-items: center;
      gap: 40px;
      font-size: 14px;
      font-weight: 600;
      line-height: 18px;
      color: #AAAFBE;

      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      .library-contract-card-text-btm-type {
        position: relative;
        display: flex;
        align-items: center;

        &:first-of-type::after{
          content: "";
          position: absolute;
          right: -20px;
          width: 1px;
          height: 23px;
          background-color: #D8D8D8;
          
        }

        .library-contract-cart-btm-type-container {
          display: flex;
          align-items: center;
        
        }


        .library-contract-card-text-btm-type-standard {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 26px;
          margin-left: 5px;
          padding: 0 10px;
          border-radius: 17px;
          background-color: #DCEDF0;

          font-weight: 500;
          color: #2E94DE;
        }

        .library-contract-card-text-btm-type-solution {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 26px;
          margin-left: 5px;
          border: 1px solid #5888FF;
          border-radius: 17px;
          padding: 3px 10px;

          font-weight: 500;
          color: #5888FF;
        }

      }

    }


  }
}
