@import "../../../styles/variable";

.checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;

  input[type="checkbox"] {
    border: 1px solid #b1b1c1;
    border-radius: 4px;
    background-color: #fff;
    cursor: pointer;
    appearance: none;
    width: 18px;
    height: 18px;
    margin: 0 10px 0 0;

    &:checked {
      border: none;
      border-radius: 4px;
      background-image: url("../../../assets/images/atom/icon_checkbox_checked.png");
      background-repeat: no-repeat;
      background-size: contain;
      cursor: pointer;
      width: 18px;
      height: 18px;
    }

    &:disabled + .main-checkbox {
      cursor: default;
    }
  }

  &-label {
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    color: $fc-main;
  }
}
