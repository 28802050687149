@import "../../../../styles/variable";

.create-node-estimated-cost-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 50px;

  .node-estimated-cost-index {
    width: 240px;
    text-align: left;
    font-weight: 500;
    color: $fc-sub;
  }

  .node-estimated-cost-data {
    &-value {
      color: $color-blue;
      font-weight: $fw-medium;
      font-size: 20px;
    }

    &-label {
      color: $fc-main;
      font-weight: $fw-medium;
      font-size: $fs-normal;
    }
  }
}

.create-node {
  &-chain-selector-container {
    display: flex;
    // flex-direction: row;
    gap: 10px;
    width: 100%;
  }
}

.keystore-file-wrapper {
  position: relative;
  width: 100%;

  .create-keystore-file-trigger {
    position: absolute;
    margin-top: 8px;
    width: 100%;
    height: fit-content;
    line-height: 18px;
    color: $color-blue;
    font-weight: $fw-medium;
    font-size: $fs-comment;
    text-align: center;

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
