.password-condition {
  display: flex;
  flex-direction: column;
  padding: 10px 16px;
  background-color: #f9f9f9;
  border-radius: 7px;

  &-title {
    color: #3f4248;
    font-size: 14px;
    font-weight: 700;
    line-height: 1.3;
  }

  &-body {
    padding-left: 18px;
    list-style-type: decimal;
    &-item {
      color: #7f879e;
      font-size: 14px;
      line-height: 1.6;
      white-space: pre-line;
    }
  }
}
