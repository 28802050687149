@import '../../../../styles/variable';

.method-request-template {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  &-content {
    display: flex;
    flex-direction: column;
    height: 580px;
  }

  &-header {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    &-name {
      font-size: $fs-index;
      color: $fc-main;
      font-weight: $fw-medium;
      line-height: 23px;
    }

    &-description {
      margin-top: 5px;
      font-size: $fs-comment;
      color: $fc-main;
    }
  }

  &-inputs {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
    margin-top: 20px;
    padding-right: 10px;

    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
      background: rgba(88, 136, 255, 0.15);
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: $color-blue;
    }
  }

  &-result {
    display: flex;
    flex-direction: column;

    &-title {
      font-size: $fs-index;
      color: $fc-main;
      font-weight: $fw-medium;
      line-height: 23px;
    }

    &-data {
      display: flex;
      width: 100%;
      height: 107px;
      margin-top: 10px;
      border-radius: 8px;
      border: 2px solid rgba(88, 136, 255, 0.5);
      padding: 14px 20px;
      overflow: auto;
    }
  }
}

.method-input-wrapper {
  &:not(:last-child) {
    margin-bottom: 20px;
  }

  &-name {
    margin-bottom: 5px;
    font-size: $fs-normal;
    color: $fc-main;
    font-weight: $fw-medium;
    line-height: 23px;
  }

  &-description {
    margin-bottom: 10px;
    font-size: $fs-comment;
    color: $fc-main;
  }
}
