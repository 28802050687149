.contracts-filter {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  width: 102px;
  height: 50px;
  border: 2px solid #efefef;
  border-radius: 8px;
  font-weight: 500;
  color: #7f879e;
  background-color: #fff;
  cursor: pointer;

  .contracts-filter-icon {
    width: 20px;
    height: 20px;
  }
}

.contracts-filter-dropdown {
  z-index: 100;
  position: absolute;
  right: 0;
  top: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 24px;
  max-width: 500px;
  width: fit-content;
  padding: 18px 19px;
  border-radius: 15px;
  background-color: #fff;
  box-shadow: 0px 4px 20px 0px #0000001f;

  .contracts-filter-dropdown-box {
  font-size: 14px;
  font-weight: 500;
    .contracts-filter-dropdown-title {
      
      font-weight: 600;
      line-height: 18px;
      color: #aaafbe;
    }

    .contracts-filter-dropdown-type {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 10px;
      margin-top: 10px;
      color: #5888FF;

      input[type="checkbox"] {
        display: none;

        & + label::before {
          content: "";
          display: inline-block;
          width: 20px;
          height: 20px;
          margin-right: 7px;
          box-shadow: 0 0 0 1px #E4E6EB inset;
          border-radius: 50%;
          background-color: #fff;
          vertical-align: middle;
        }

        &:checked + label::before {
          background: url('../../../../assets/images/icon_circle_check.png') no-repeat;
          background-size: 20px 20px;
        }
      }


      .contracts-filter-dropdown-tag {
        label {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 26px;
          padding: 0 10px 0 3px;
          border: 1px solid #5888FF;
          border-radius: 17px;
          font-size: 14px;
          cursor: pointer;
        }
      }


      .contracts-filter-dropdown-category {
        border-radius: 17px;
        cursor: pointer;

        label {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 26px;
          padding: 0 10px 0 3px;
          cursor: pointer;
        }
      }
    }
  }

  .contracts-filter-dropdown-bar {
    width: 100%;
    height: 1px;
    background-color: #d8d8d8;
  }
}

