@import "../../../styles/variable";

#FaqSnb {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  left: calc(50% - 600px);
  width: 180px;

  .faq-box {
    display: flex;
    flex-direction: column;
    padding: 20px;
    background: $color-white;
    border: 1px solid $color-border-liner;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05), 0 2px 5px rgba(61, 84, 128, 0.1);
    border-radius: 8px;

    .faq__tab {
      width: fit-content;
      margin-bottom: 30px;
      font-weight: 500;
      font-size: 18px;
      line-height: 23px;
      color: $fc-placeholder;
      cursor: pointer;
      &.true {
        text-decoration: underline;
        color: $color-blue;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
