@import "../../../../styles/variable";

.foundation-notice {
  width: 100%;

  &-img {
    width: 100%;
    height: 260px;
    margin: 0 0 20px;

    &-src {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 8px;
    }
  }

  &-description {
    word-break: break-all;
    white-space: pre-line;
    color: $fc-main;
    line-height: 21px;
  }
}
