.radio-buttons {
  display: flex;
  align-items: center;

  .radio-button-container {
    display: flex;
    align-items: center;

    &.column {
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;
    }
  }
}
