@import "../../../view/styles/variable";

.section {
  display: flex;
  flex-direction: column;
  border: 1px solid #ececec;
  border-radius: 16px;
  background-color: $color-white;
  box-shadow: 0 1px 1px rgb(0 0 0 / 5%), 0 2px 5px rgb(61 84 128 / 10%);
  padding: 30px 20px;

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  .section-header {
    display: flex;
    flex-direction: column;

    .section-title {
      font-size: 20px;
      font-weight: 500;
      line-height: 26px;
      color: $fc-main;
    }

    .section-description {
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      white-space: pre-wrap;
      color: $fc-main;
    }
  }

  .section-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    &:not(:last-child) {
      border-bottom: 1px solid $color-border-liner;
      margin-bottom: 20px;
      padding-bottom: 20px;
    }

    .section-content-label {
      font-size: 18px;
      font-weight: 500;
      line-height: 23px;
      color: $fc-placeholder;
      margin-bottom: 20px;
    }

    .section-row-data {
      display: flex;
      flex-direction: row;
      line-height: 21px;
      width: 100%;
      min-height: fit-content;

      &:not(:last-child) {
        margin-bottom: 25px;
      }

      .section-row-index {
        display: flex;
        align-items: center;
        font-weight: 500;
        text-align: left;
        color: $fc-sub;
        width: 270px;
        padding-left: 10px;
      }

      .section-row-value {
        flex: 1;
        font-size: 16px;
        word-break: break-word;
      }
    }
  }
}

.section-wrapper {
  .section-wrapper-header {
    display: flex;
    align-items: center;
    height: 50px;

    .section-image {
      margin-right: 5px;
      width: 18px;
      object-fit: contain;
    }

    .section-label {
      font-size: 14px;
    }
  }
}

.section-wrapper-content {
  &-data {
    color: $fc-main;

    &-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: $fs-normal;
      line-height: 21px;
      color: $fc-main;
      margin: 0 0 20px;

      &-label {
        font-weight: $fw-medium;
        width: 180px;
        min-width: 180px;
        max-width: 180px;
      }

      &-value {
        word-break: break-word;

        &-description {
          font-size: $fs-comment;
          color: $fc-sub;
        }
      }

      &:last-child {
        margin: 0;
      }
    }

    &-information {
      display: flex;
      align-items: center;
      margin: 0 0 20px;

      &-image {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 2px solid $color-border-liner;
        border-radius: 8px;
        background-color: #fff;
        overflow: hidden;
        width: 60px;
        height: 60px;

        &-logo {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &-title {
        font-size: 24px;
        font-weight: $fw-medium;
        line-height: 30px;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 560px;
        margin: 0 0 0 20px;
      }
    }

    &-description {
      font-size: $fs-normal;
      white-space: pre-line;
      word-break: break-all;
      width: 100%;
    }
  }
}
