@import "../../../../../styles/variable";
@import "../../../../../styles/mixins";

.node-preset-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 48px;
  border-radius: 16px;
  border: 1px solid #ececec;
  background: radial-gradient(268.23% 141.42% at 0% 0%, #fff 0%, #f5f8fd 100%);
  transition: 0.15s ease-in-out;
  cursor: pointer;

  &.disabled {
    cursor: default;

    &:hover {
      border: 1px solid #ececec;
      box-shadow: none;
    }
  }

  &:hover {
    border: 1px solid $color-blue;
    box-shadow: 0px 2px 5px 0px rgba(61, 84, 128, 0.1), 0px 1px 1px 0px rgba(0, 0, 0, 0.05);
  }

  &.selected > .node-preset-box-info .node-preset-box-info-title {
    color: $fc-main;
  }

  &.selected > .node-preset-box-info .node-preset-box-info-description {
    color: #7d828d;
  }

  &.selected {
    border: 1px solid $color-blue;
    box-shadow: 0px 2px 5px 0px rgba(61, 84, 128, 0.1), 0px 1px 1px 0px rgba(0, 0, 0, 0.05);
  }

  &-info {
    width: 300px;

    &-title {
      margin-bottom: 5px;
      color: #7d828d;
      font-size: 20px;
      font-weight: 700;
    }

    &-description {
      color: #848a96;
      font-size: 12px;
      font-weight: 500;
      word-break: keep-all;
      white-space: pre-line;
    }
  }

  &-content {
    display: grid;
    grid-template-columns: repeat(3, 115px);
    grid-gap: 5px;

    &-item {
      display: flex;
      align-items: center;

      &-icon {
        margin-right: 5px;
        width: 20px;
        height: 20px;

        &-src {
          width: 100%;
          object-fit: contain;
        }
      }

      &-label {
        color: $fc-main;
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
}
