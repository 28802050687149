.reset-password {
  &-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 48px;
    padding: 150px 0 0;
    width: 100%;
    height: 100%;

    &-logo {
      height: 30px;
      object-fit: contain;
    }

    &-body {
      &-password-condition {
      }
    }
  }

  &-button-container {
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }
}

.reset-password-invalid {
  &-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 48px;
    padding: 150px 0 0;
    width: 100%;
    height: 100%;
  }

  &-logo {
    height: 30px;
    object-fit: contain;
  }

  &-content-description {
    line-height: 18px;
    white-space: pre-line;
    text-align: center;
  }

  &-button-container {
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }
}
