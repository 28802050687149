@import "../../../../styles/mixins";
@import "../../../../styles/variable";

#CreateProject {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  background-color: #fff;

  .create-project {
    &-wrapper {
      @include remove-scroll;
      width: 70%;
      overflow-y: auto;
      padding: 50px;
    }

    &-background {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 30%;
      background-color: #f7f7fc;

      &-image {
        height: 315px;
        object-fit: contain;

        &-src {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    &-header {
      display: flex;
      flex-direction: column;
      margin-bottom: 40px;

      &-title {
        font-size: 32px;
        font-weight: 700;
        line-height: 38px;
        letter-spacing: 0.005em;
        color: #11142d;
        margin-bottom: 10px;
      }
      &-description {
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0.005em;
        color: #11142d;
      }
    }

    &-label {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      &-icon-box {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #ebf0f5;
        border-radius: 8px;
        background: #f9fafb;
        width: 34px;
        height: 34px;
        margin-right: 5px;
      }
      &-icon {
        width: 24px;
        height: 24px;
        object-fit: contain;
      }
      &-text {
        font-size: 20px;
        font-weight: 700;
        line-height: 26px;
        color: #7d828d;
      }
    }
  }
}
