@import "../../../styles/variable";

.basic-input {
  display: flex;
  align-items: center;
  border: solid 2px $color-border-liner;
  background-color: #ffffff;
  color: $fc-main;
  width: 100%;

  &:disabled {
    padding: 0 20px !important;
    border: 2px solid $color-border-liner !important;
    background-color: #f4f6f8 !important;
    cursor: default;
  }

  &:read-only {
    border: none;
    background-color: #fff;

    &.default {
      padding: 0;
    }
  }

  &::placeholder {
    color: $fc-placeholder;
  }

  &:focus {
    outline: none;
  }
}

.default {
  padding: 0 20px;
  height: $input-height;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  line-height: 21px;
  border-radius: 8px;
}
