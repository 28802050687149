@import "../../../../view/styles/variable";

.progress-state-content-box {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 46px 0 30px;
  width: 100%;
  height: fit-content;
  background: #f4f6f8;
  border-radius: 16px;
  overflow: hidden;

  .progress-state {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;

    &-box {
      position: relative;
      margin: 0 0 45px;
      width: 100%;

      .progress-line-wrapper {
        position: absolute;
        display: flex;
        flex-direction: row;
        left: 50%;
        transform: translateX(-50%);

        .progress-line {
          position: relative;
          border-bottom-width: 2px;
          border-bottom-style: solid;
          border-bottom-color: $color-gray;

          &.active {
            border-bottom-color: $color-blue;
          }

          &:before {
            content: "";
            position: absolute;
            top: -3px;
            right: -4px;
            width: 8px;
            height: 8px;
            border-radius: 100%;
            background-color: $color-gray;
          }
        }
      }

      .progress-state-wrapper {
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0);
        display: flex;

        .progress-state-helper {
          position: relative;
        }

        .progress-state-container {
          position: absolute;
          top: -15px;
          right: 0;
          display: flex;
          align-items: center;
          flex-direction: column;
          width: 100%;

          .progress-state-pointer {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 30px;
            height: 30px;
            border-radius: 100%;
            background-color: $color-blue;
            opacity: 0;

            &.active {
              opacity: 1;
            }

            &-check-icon {
              width: 16px;
              object-fit: contain;
            }

            &-dot {
              width: 8px;
              height: 8px;
              border-radius: 100%;
              background-color: $color-white;
            }
          }

          &-label {
            margin-top: 10px;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            text-align: center;
            color: $fc-placeholder;

            &.active {
              color: $color-blue;
            }
          }
        }
      }
    }

    &-description {
      padding-top: 22px;
      font-weight: $fw-regular;
      font-size: $fs-small;
      color: $fc-sub;
      line-height: 15px;
      white-space: pre-line;
      text-align: center;
    }
  }
}
