@import "../../../view/styles/variable";

.modal-template-A {
  display: flex;
  flex-direction: column;
  padding: 40px;
  border-radius: 20px;
  background-color: $color-white;
  width: 645px;

  .modal-template-A-header {
    margin-bottom: 40px;
    padding-bottom: 40px;
    font-weight: $fw-medium;
    font-size: $fs-modal-title;
    text-align: center;
    color: $fc-main;
    border-bottom: 1px solid #ececec;
  }
}
