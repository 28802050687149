#Paging{
  .paging-wrapper{
    display: flex;
    justify-content: center;
    margin: 20px 0;
    .pagination-wrapper{
      display: flex;
      align-items: center;
      .paging-number{
        margin: 0 7px;
        font-size: 16px;
        cursor: pointer;
        &.selected{
          color:#0d67f1;
        }
      }
    }
    .paging-left{
      display: flex;
      align-items: center;
      margin-right: 18px;
      cursor: pointer;
      .paging-left-img{
        width: 7px;
        object-fit: contain;
      }
    }
    .paging-right{
      display: flex;
      align-items: center;
      margin-left: 18px;
      cursor:pointer;
      .paging-right-img{
        width: 7px;
        object-fit: contain;
      }
    }
  }
}