.kit-tab {
  display: flex;
  gap: 20px;

  .kit-tab-item {
    font-weight: 600;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: -0.01em;
    color: #7F879E;
    cursor: pointer;
    transition: all 0.4s;

    &:hover {
      color: #252A3F;
    }

    &.active {
      color: #252A3F;
    }
  }
}
