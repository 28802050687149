@import "../../../styles/variable";

.delete-mfa-modal {
  display: flex;
  width: 565px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;

  &-description {
    line-height: 30px;
    white-space: pre-line;
    text-align: center;
    color: $fc-main;
  }
}
