@import "../../../../view/styles/variable";
.table-status-label {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 6px 8px;
  width: fit-content;
  border-radius: 8px;
  cursor: default;

  &-icon {
    margin-right: 5px;
    width: 13px;
    object-fit: contain;
  }

  &-label {
    font-size: $fs-normal;
    font-weight: $fw-medium;
    line-height: 21px;
  }
}
