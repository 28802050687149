@import "../../../../styles/variable";

.payment-gateway-type-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 20px;

  .payment-gateway-type-container {
    flex: 1;
    padding: 24px;
    border: 1px solid #ececec;
    border-radius: 16px;
    background: #fff;
    transition: 0.15s ease-in-out;

    .payment-gateway-type {
      display: flex;
      flex-direction: column;
      gap: 40px;

      &-title {
        font-size: 32px;
        font-weight: 700;
      }

      &-description {
        &-header {
          margin-bottom: 20px;
          font-size: 18px;
          font-weight: 700;
        }

        &-content {
          min-height: 50px;
          font-size: 14px;
          font-weight: 500;
          white-space: pre-line;
          line-height: 1.16;
        }
      }

      &-available-asset {
        &-header {
          margin-bottom: 6px;
          color: #7f879e;
          font-size: 14px;
          font-weight: 700;
        }

        &-container {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 20px;
          padding: 10px 16px;
          background-color: #f6f8f9;
          border-radius: 16px;

          .payment-gateway-type-available-asset-item {
            display: flex;
            align-items: center;
            gap: 10px;

            &-icon {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 24px;
              height: 24px;
              background-color: #e4e6eb;
              border-radius: 100%;

              &-src {
                width: 16px;
                object-fit: contain;
              }
            }

            &-label {
              font-size: 14px;
            }
          }
        }
      }

      &-button {
        display: flex;
        justify-content: center;
      }
    }
  }
}
