@import "../../../styles/mixins";

.network-selector {
  border: 2px solid #e7e9ef;
  border-radius: 8px;
  background-color: #fff;
  padding: 14px 20px;
  width: 100%;
  max-height: 250px;

  &-container {
    @include custom-scroll;
    overflow-y: auto;
    max-height: 222px;
  }

  &-item {
    display: flex;
    align-items: center;
    border-radius: 4px;
    transition: 0.15s ease-in-out;
    margin-right: 5px;
    padding: 5px;

    &:hover {
      background-color: #e7e9ef;
    }
  }
}
