@import "../../../styles/variable";

.icon-selector {
  position: relative;
  display: flex;

  &-preview {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    // border: 2px solid #e7e9ef;
    background-color: #f5f7ff;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    color: #b1b1c1;
    overflow: hidden;
    cursor: pointer;

    .hover-edit {
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 16px;
      background-color: #ffffff90;
      font-size: 16px;
      font-weight: 500;
      line-height: 21px;
      color: $color-primary;
      cursor: pointer;
      width: 100%;
      height: 100%;
      &-icon {
        width: 24px;
        object-fit: contain;
      }
    }

    &-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.default-image-popup {
  position: absolute;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  background-color: #ffffff;
  box-shadow: 0 2px 60px rgba(61, 84, 128, 0.2);
  z-index: 2;
  padding: 20px;
  &-text {
    font-size: 20px;
    font-weight: 500;
    line-height: 26px;
    color: #7d828d;
    margin-bottom: 20px;
  }
  .default-image-container {
    display: grid;
    grid-row-gap: 20px;
    grid-column-gap: 10px;
    grid-template-columns: repeat(4, 1fr);
    margin-bottom: 20px;
  }
  .default-image-popup-upload {
    display: flex;
    justify-content: center;
    align-items: center;
    &-text {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      transition: all 0.15s ease;
      font-size: 20px;
      font-weight: 500;
      line-height: 26px;
      cursor: pointer;
      padding: 10px;
      &:hover {
        background-color: #e7ecff;
      }
    }
  }
}
