.network-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  padding: 24px;
  border-radius: 16px;
  background: #fff;

  &.able {
    cursor: pointer;
  }

  .network-id-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 55px;
    padding-bottom: 0px;

    .network-id-title {
      color: #7f879e;
      font-size: 16px;
      font-weight: 700;
      line-height: 30px;
    }

    .network-id {
      color: #3f4248;
      font-size: 24px;
      font-weight: 700;
      line-height: 30px; /* 125% */
    }
  }

  .network-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;
    padding-left: 20px;
    width: 280px;
    gap: 10px;

    .network-name {
      align-self: stretch;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      font-size: 24px;
      font-weight: 700;
      line-height: 30px;
    }

    .network-coin-wrapper {
      display: flex;
      align-items: center;
      gap: 5px;

      .network-coin-icon {
        display: flex;
        align-items: flex-start;
        width: 30px;
        height: 30px;
        border-radius: 65px;
        background: #fff;
        gap: 10px;
      }

      .network-coin-text {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        flex: 1 0 0;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #8d9092;
        font-size: 18px;
        font-weight: 500;
        line-height: 30px;
      }
    }
  }

  .network-type-info {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .network-type-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 110px;
      padding: 5px 15px;
      gap: 5px;
      border-radius: 20px;
      background: #f1f4f6;

      .network-type-icon {
        width: 24px;
        object-fit: contain;
      }

      .network-type-text {
        color: #5888ff;
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
      }
    }
  }

  .network-node-info {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 0 5px;
    height: 100px;
    border-radius: 16px;
    border: 1.5px solid var(--lgrey-02, #efefef);

    .network-node-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 14px;
      gap: 8px;

      .network-node-label {
        color: #7f879e;
        font-size: 14px;
        font-weight: 500;
        line-height: 30px;
      }

      .network-node-info-wrapper {
        display: flex;
        align-items: center;
        gap: 8px;

        .network-node-icon {
          width: 18px;
          object-fit: contain;
        }

        .network-node-text {
          width: 112px;
          color: #252a3f;
          text-overflow: ellipsis;
          font-size: 14px;
          font-weight: 500;
          line-height: 18px;
        }
      }
    }

    .node-divide-line {
      width: 2px;
      height: 32px;
      background: #f3f3f3;
    }
  }

  .network-status-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    width: 200px;
    gap: 10px;

    .network-status-info-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-bottom: 0px;
      gap: 14px;
    }

    .network-status-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .network-status-label {
      color: #7f879e;
      font-size: 16px;
      font-weight: 700;
      font-style: normal;
      line-height: 30px;
    }

    .network-status-value {
      color: #3f4248;
      font-size: 24px;
      font-weight: 700;
      line-height: 30px;
    }

    .network-status-step-wrapper {
      display: grid;
      grid-template-columns: repeat(5, 20px);
      gap: 5px;

      .network-status {
        height: 5px;
        background: #e4e6eb;

        &.active {
        }
      }
    }
  }
}
