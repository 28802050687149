@import "../../../styles/variable";

.end-point-label-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 21px;
  border-radius: 16px;

  &.mainnet {
    background: $color-primary;
  }

  &.testnet {
    background: #f2c440;
  }

  .end-point-label {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #fff;
  }

  .end-point-label-icon {
    margin-right: 3px;
    height: 10px;
  }
}
