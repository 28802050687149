@import "../../../../styles/variable";

.minted-nft-box {
  .minted-nft-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 20px;
    column-gap: 20px;
  }

  .empty-nft-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 66px 0 97px;

    .empty-nft-image {
      margin: 0 0 20px;
      width: 140px;
      height: 140px;

      &-src {
        width: 100%;
        object-fit: contain;
      }
    }

    .empty-nft-label {
      font-weight: $fw-medium;
      font-size: $fs-18;
      color: $fc-sub;
    }
  }
}
