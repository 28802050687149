.register-block-explorer {
  &-chain-selector-container {
    display: flex;
    gap: 10px;
    width: 100%;
  }

  &-button-container {
    display: flex;
    justify-content: flex-end;
  }
}
