@import "../../../../styles/variable";

.find-password {
  &-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 48px;
    padding: 150px 0 0;
    width: 100%;
    height: 100%;
  }

  &-logo {
    height: 30px;
    object-fit: contain;
  }

  // STEP 1
  &-enter-email {
    width: 520px;

    &-button-container {
      display: flex;
      justify-content: center;
      margin-top: 40px;
    }
  }

  // STEP 2
  &-success {
    &-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;

      &-description {
        line-height: 18px;
        text-align: center;
        color: $fc-main;

        &-email {
          color: $color-primary;
        }
      }

      &-receive {
        &-trigger {
          color: $color-primary;
          cursor: pointer;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    &-button-container {
      margin-top: 40px;
      display: flex;
      justify-content: center;
    }
  }
}
