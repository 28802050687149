@import "../../../../../../view/styles/variable";

.participation-history-table-detail-icon {
  width: 20px;
  object-fit: contain;
  cursor: pointer;
}

.faucet-received-participations {
  &-container {
    width: 100%;
  }

  &-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: $fs-normal;
    line-height: 21px;
    color: $fc-main;
    margin: 0 0 20px;

    &-value {
      font-weight: $fs-comment;
      word-break: break-word;

      &-description {
        font-size: $fs-comment;
        color: $fc-sub;
      }
    }
  }
}
