@import "../../../../styles/mixins";
@import "../../../../styles/variable";

.create-project-step2 {
  .initial-project-plan-container {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(4, 1fr);

    @include media(2000px) {
      grid-template-columns: repeat(2, 1fr);
    }

    .initial-price-plan-wrapper {
      .initial-price-plan {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 80px 0;
        border: 1px solid #e7e9ef;
        border-radius: 16px;
        transition: 0.15s ease-in-out;
        cursor: pointer;

        &:hover {
          box-shadow: 0 5px 5px rgba(0, 0, 0, 0.3);
          transform: translateY(-2px);
        }

        &:active {
          box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
          transform: translateY(1px);
        }

        &-option-list {
          margin: 40px 0;
          list-style-type: disc;
        }

        &-option {
          font-size: 16px;
          font-weight: 400;
          line-height: 21px;
          color: $fc-main;

          &:not(:last-child) {
            margin-bottom: 8px;
          }
        }
      }

      .initial-plan-price {
        display: flex;
        align-items: flex-end;
        height: 60px;
        margin-bottom: 40px;
        .initial-per-price {
          font-size: 48px;
          font-weight: 700;
          color: $fc-main;
        }
        .initial-month {
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          color: $fc-main;
        }
      }
    }
  }
}
