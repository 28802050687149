@import "../../../../view/styles/variable";

.file-drag-and-drop {
  flex: 1;
  width: 100%;

  .upload-filed {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $color-white;
    border-radius: 8px;
    width: 100%;

    .upload-file-icon {
      margin-right: 10px;
      height: 16px;
      object-fit: contain;
    }

    .upload-file-label {
      max-width: calc(100% - 112px);
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-weight: $fw-light;
      color: $fc-placeholder;

      &.file {
        color: $fc-main;
      }
    }

    .delete-uploaded-file-btn {
      margin-left: 10px;
      width: 16px;
      object-fit: contain;
    }
  }
}
