@import "../../../../../styles/variable";

.airdrop-deposit-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;

  &-description {
    width: 100%;
    margin: 0 auto 30px;
    line-height: 21px;
    color: $fc-sub;
    white-space: pre-line;
    text-align: center;
  }

  &-information {
    margin-bottom: 25px;
    padding: 0 20px;
    &-row {
      margin: 0 0 20px;

      &:last-child {
        margin: 0;
      }

      &-label {
        display: flex;
        align-items: center;
        margin: 0 0 10px;
        color: $fc-sub;
        font-weight: $fw-medium;
        &-bullet-point {
          margin-right: 10px;
          width: 5px;
          object-fit: contain;
        }
      }
      &-value {
        margin-left: 15px;
      }
    }
  }
}
