@import "variable";

@mixin tablet {
  @media only screen and (min-width: 768px) {
    @content;
  }
}

@mixin laptop {
  @media only screen and (min-width: 992px) {
    @content;
  }
}

@mixin desktop {
  @media only screen and (min-width: 1200px) {
    @content;
  }
}

@mixin media($width) {
  @media only screen and (max-width: $width) {
    @content;
  }
}

@mixin remove-scroll {
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

@mixin custom-scroll {
  &::-webkit-scrollbar {
    width: 5px;
    background-color: #b3b6be50;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #b3b6be;
    border-radius: 4px;
  }
}

@mixin multi-line-text-ellipsis($line) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
  word-break: break-all;
}

// width 영역 잡혀 있어야 함
@mixin text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// @include media(768px) { color: red; };

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}

// @include border-radius(5px);

@mixin font-face($name, $file, $weight: noraml, $style: normal) {
  @font-face {
    font-family: "#{$name}";
    font-weight: $weight;
    font-style: $style;
    src: url("../assets/fonts/#{$file}.otf") format("truetype");
  }
}

// @include border-box();
@mixin border-box() {
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
}

// @include font-face('설정할 폰트 이름', '폰트 파일 이름');
// @include font-face('설정할 폰트 이름', '폰트 파일 이름', bold);

// 그리드 레이아웃을 적용하기 이전 변경된 레이아웃 믹스인

@mixin article-810() {
  position: relative;
  width: 810px;
  height: fit-content;
  margin: 0;
  padding: 50px 50px 0;
  // margin: 0 auto;
  box-sizing: content-box;
}

@mixin article-812() {
  position: relative;
  width: 812px;
  height: fit-content;
  margin: 0;
  padding: 40px 40px 0;
  // margin: 0 auto;
  box-sizing: content-box;
}

@mixin article-1080() {
  position: relative;
  width: 1080px;
  height: fit-content;
  margin: 0;
  padding: 50px 50px 0;
  // margin: 0 auto;
  box-sizing: content-box;
}

@mixin article-1240() {
  position: relative;
  width: 1240px;
  height: fit-content;
  padding: 50px 50px;
  // margin: 0 auto;
  box-sizing: content-box;
}

@mixin article-700() {
  position: relative;
  width: 700px;
  height: fit-content;
  //margin: 10px auto 0;
  //padding: 44px 40px 50px;
  box-sizing: border-box;
}

@mixin article-main() {
  position: relative;
  width: 1200px;
  height: fit-content;
  margin: 10px auto 0;
  padding: 44px 40px 50px;
  box-sizing: border-box;
  background-color: white;
}

@mixin article-main-no-padding() {
  position: relative;
  width: 1200px;
  height: fit-content;
  margin: 10px auto 0;
  padding-top: 44px;
  box-sizing: border-box;
  // background-color: white;
}

@mixin article-initialize() {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-width: 900px;
  min-height: 100vh;
  padding: 70px 0 100px;
  margin: 0 auto;
  box-sizing: border-box;
  background-color: #ffffff;
}

@mixin Wrapper_30_20($direction) {
  display: flex;
  flex-direction: $direction;
  padding: 30px 20px;
}

@mixin Wrapper_20($direction) {
  display: flex;
  flex-direction: $direction;
  padding: 20px;
}
