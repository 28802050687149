@import "../../../view/styles/variable";

.table {
  &:not(:last-child) {
    margin-bottom: 40px;
  }

  .table-index {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .table-index-icon {
      width: 18px;
      margin-right: 3px;
      object-fit: contain;
    }

    .table-index-text {
      font-weight: $fw-medium;
    }
  }

  .main-table {
    border-radius: 16px;
    background-color: $color-white;
    box-shadow: (0px 1px 1px rgba(0, 0, 0, 0.05)), (0px 2px 5px rgba(61, 84, 128, 0.1));
    box-sizing: border-box;
    table-layout: fixed;
    border-collapse: separate !important;
    font-size: $fs-normal;
    color: $fc-main;
    width: 100%;

    thead {
      tr {
        background-color: #F4F6F8;
        color: #33373b;
        height: 56px;

        th {
          vertical-align: middle;
          color: $fc-main;

          &:first-child {
            border-top-left-radius: 16px;
          }

          &:last-child {
            border-top-right-radius: 16px;
          }
        }
      }
    }

    tbody {
      font-weight: normal;
      letter-spacing: -0.32px;

      tr {
        cursor: pointer;
        height: 97px;

        &:hover {
          td {
            background-color: #f4f6f8;
            transition: background-color 0.25s ease;
          }
        }

        &:last-child {
          td {
            &:first-child {
              border-bottom-left-radius: 16px;
            }

            &:last-child {
              border-bottom-right-radius: 16px;
            }
          }
        }

        td {
          border-bottom: 1px solid $color-border-liner;
          overflow: hidden;
          text-align: center;
          vertical-align: middle;
          white-space: nowrap;
          text-overflow: ellipsis;
          padding: 0 11px;

          &.status-container {
            overflow: visible;
          }

          .image-data {
            border-radius: 6px;
            font-size: 12px;
            color: $fc-placeholder;
            width: 74px;
            height: 74px;
            object-fit: cover;
          }

          .no-img {
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid #f4f6f8;
            border-radius: 6px;
            font-size: 12px;
            font-weight: bold;
            color: $fc-placeholder;
            width: 74px;
            height: 74px;
          }
        }

        &:first-child {
          td {
            border-top: 1px solid $color-border-liner;
          }
        }

        &:last-child {
          td {
            border-bottom: none;
          }
        }
      }
    }

    .table-no-data {
      font-size: $fs-14;
      font-weight: $fw-medium;
      cursor: default;
    }

    .add-row {
      font-size: 24px;
      font-weight: 300;
      color: #297cfd;
    }
  }
}
