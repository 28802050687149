@import "../../../styles/variable";

.project-selector {
  --item-padding: 11px 15px;
  --item-border: 1px solid #f5f6f8;

  position: relative;
  padding: 8px 16px;
  width: 220px;
  height: 36px;
  background-color: #f4f8ff;
  border-radius: 16px;
  border: var(--item-border);
  transition: 0.15s ease-in-out;
  cursor: pointer;

  &:hover {
    border: 1px solid $color-primary;
  }

  &.active {
    border: 1px solid $color-primary;
  }

  &-title-container {
    position: absolute;
    top: -8px;
    left: 16px;

    .project-selector-title-area {
      position: relative;
      width: fit-content;

      .project-selector-title {
        position: absolute;
        top: 0;
        left: 0;
        font-size: 12px;
        color: $color-secondary;
      }

      .project-selector-helper {
        position: absolute;
        top: 7px;
        left: -2px;
        width: 41px;
        border: 1px solid #f4f8ff;
      }
    }
  }

  &-area {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-selected-project {
      display: flex;
      align-items: center;
      width: 100%;

      &-label {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        padding: 1px 0;
        width: 158px;
        font-size: 14px;
        font-weight: 500;
        color: $color-primary;
      }
    }

    &-arrow {
      min-width: 16px;
      width: 16px;
      height: 16px;

      &.active {
        transform: rotate(180deg);
      }

      &-icon {
        width: 100%;
        object-fit: contain;
      }
    }
  }

  &-panel {
    position: absolute;
    left: 0;
    padding-top: 6px;
    top: 36px;
    width: 220px;
    background-color: #fff;
    border: var(--item-border);
    box-shadow: 0px 4px 30px 0px rgba(127, 135, 158, 0.15);
    border-radius: 16px;

    &-header {
      height: 4px;
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
    }

    &-project {
      &-item {
        position: relative;
        padding: var(--item-padding);
        border-top: var(--item-border);
        background-color: #fff;
        transition: 0.15s ease-in-out;
        cursor: pointer;

        &:hover {
          background-color: #f4f8ff;
        }

        &-label {
          display: flex;
          align-items: center;
          width: 100%;

          &-dot {
            margin-right: 10px;
            width: 8px;
            height: 8px;
            border-radius: 100%;
            background-color: $color-primary;
            opacity: 0.3;

            &.selected {
              opacity: 1;
            }
          }

          &-name {
            padding: 2px 0;
            font-size: 14px;
            font-weight: 500;
            color: #7f879e;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
        }
      }
    }

    &-new-project {
      padding: var(--item-padding);
      border-top: var(--item-border);
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;
      transition: 0.15s ease-in-out;
      font-size: 14px;
      font-weight: 500;
      color: $color-primary;
      cursor: pointer;

      &:hover {
        background-color: #f4f8ff;
      }
    }
  }
}
