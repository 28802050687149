.billing-history-table {
  &-header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #b1b1c1;

    &-item {
      width: 33.3%;
      padding: 7px 10px;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      color: #7f879e;
    }
  }

  &-body {
    margin-bottom: 20px;

    &-empty {
      padding: 11px 0;
      color: #1b2124;
      font-size: 14px;
      font-weight: 700;
      text-align: center;
      border-bottom: 1px solid #efefef;
    }

    &-content {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #efefef;

      &-item {
        width: 33.3%;
        padding: 11px 10px;
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
      }
    }
  }
}
