@import "../../../../view/styles/variable";

.empty-list-template {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 400px;
  background: $color-white;
  border: 1px solid #ececec;
  border-radius: 16px;

  &-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .empty-list-image {
      width: 150px;
      height: 150px;
      margin: 0 0 20px;

      &-src {
        width: 100%;
        object-fit: contain;
      }
    }

    .empty-list-description {
      font-size: $fs-index;
      font-weight: $fw-medium;
      color: $fc-main;
      white-space: pre-line;
      text-align: center;
      line-height: 21px;
    }
  }
}
