.copy-label-with-icon {
  display: flex;
  align-items: center;
  position: relative;

  .dashboard-copy-label {
    width: 414px;

    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .copy-icon-wrapper {
    display: flex;
    align-items: center;
    background-color: transparent;
    cursor: pointer;
  }

  .copy-icon {
    margin-left: 3px;
    width: 25px;
    object-fit: contain;
  }
}