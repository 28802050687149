@import "../../../../view/styles/variable";

.paging-number {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: $fc-sub;
  font-weight: $fw-medium;
  font-size: $fs-comment;

  .paging-number-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 200px;

    .paging-number-input {
      width: 34px;
      height: 22px;
      padding: 0;
      background: #ececec;
      border: none;
      border-radius: 5px;
      text-align: center;
      outline: none;
    }

    .paging-number-button-icon {
      width: 16px;
      object-fit: contain;
      cursor: pointer;
    }
  }
}
