@import "../../../../../styles/variable";

.node-delete-modal {
  display: flex;
  flex-direction: column;
  align-items: center;

  color: #3f4248;
  text-align: center;

  &-description {
    margin-bottom: 40px;
    line-height: 30px;
    text-align: center;
    white-space: pre-wrap;
    word-break: break-all;
    color: $fc-sub;
    font-size: 16px;
    font-weight: 400;
  }
}
