@import "../../../../view/styles/variable";

.multiple-input-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;

  .multiple-input-item-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10px;

    .remove-input-button {
      width: 16px;
      object-fit: contain;
      cursor: pointer;
    }
  }
  .add-input-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
    border: 2px dashed $color-border-liner;
    border-radius: 8px;
    cursor: pointer;

    .add-input-button-icon {
      width: 16px;
      object-fit: contain;
    }
  }
}
