@import "../../../view/styles/variable";

.atom-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 220px;
  height: 48px;
  border-radius: 8px;
  border: none;
  box-shadow: 0 2px 20px rgba(116, 116, 116, 0.16);
  font-size: $fs-normal;
  font-weight: $fw-medium;
  &:not(:last-child) {
    margin-right: 20px;
  }

  .button-image {
    margin: 0 5px 0 0;
    width: 16px;
    object-fit: contain;
  }
}

.icon-only {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
}

.main {
  background-color: $color-blue;
  color: $color-white;
  &:disabled {
    background-color: $color-light-gray;
    cursor: default;
  }
}
.create {
  width: 280px;
  background: $color-blue-gradient;
  color: $color-white;
}

.border-blue {
  background-color: $color-white;
  border: 1px solid $color-blue;
  color: $color-blue;
}

.border-red {
  background-color: $color-white;
  border: 1px solid $color-red;
  color: $color-red;
}

.round {
  width: 130px;
  height: 40px;
  border-radius: 20px;
  font-size: 14px;
  &:disabled {
    background-color: $color-light-gray;
    color: $color-white;
    border: none;
    cursor: default;
  }
}

.addition {
  padding: 0 10px;
  min-width: 200px;
  width: fit-content;
  height: 40px;
  background-color: $color-skyblue;
  border: 1px solid rgba(38, 87, 220, 0.2);
  color: $color-blue;
  font-size: $fs-comment;
}

.addition-main {
  width: 200px;
  height: 40px;
  font-size: $fs-comment;
}

.initialize {
  width: 247px;
  height: 67px;
  font-weight: 500;
  font-size: 20px;
}

.white {
  background-color: $color-white;
  color: $fc-main;
  box-shadow: 0 2px 20px 0 rgba(116, 116, 116, 0.161);
}

.download {
  width: fit-content;
  height: fit-content;
  padding: 10px 20px;
  border-radius: 20px;
  font-size: $fs-comment;
}

.list-round {
  padding: 11px 20px;
  min-width: 130px;
  width: fit-content;
  height: 40px;
  background: $color-blue;
  border-radius: 20px;
  color: $color-white;
  font-size: $fs-comment;

  &:disabled {
    background-color: $color-light-gray;
    color: $color-white;
    border: none;
    cursor: default;
  }
}
