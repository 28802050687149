#ManageTokenKit {
  display: flex;
  flex-direction: column;

  .kit-title-wrapper {
    padding: 30px 50px;
    background-color: #F9FAFB;
    cursor: pointer;

    .kit-info-wrapper {
      display: flex;
      align-items: center;
      gap: 11px;

      .kit-info-image {
        width: 28px;
        height: 28px;
        border: 1px solid #EFEFEF;
        border-radius: 40px;
        object-fit: contain;
        transition: width 0.3s, height 0.3s;

        &.open {
          width: 80px;
          height: 80px;
        }
      }

      .kit-info-name {
        font-size: 24px;
        font-weight: 700;
        line-height: 28px;
      }

      .kit-info-toggle {
        width: 20px;
        height: 20px;
        object-fit: contain;
        transition: transform 0.3s;
        transform: rotate(-180deg);

        &.open {
          transform: rotate(0deg);
        }
      }

      .kit-info-text {
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
      }
    }

    .kit-description {
      font-size: 16px;
      font-weight: 400;
      line-height: 19px;
    }
  }

  .kit-tab-wrapper {
    display: flex;
    padding: 15px 50px;
    background-color: #FFFFFF;
  }

  .sub-menu-container {
    display: flex;
    flex-direction: column;
    gap: 6px;
    width: 220px;
    height: 100%;
    padding: 40px 20px;
    background-color: #FFFFFF;

    .sub-menu-wrapper {
      display: flex;
      flex-direction: column;
      gap: 6px;
      position: sticky;
      top: var(--kit-title-wrapper-height);
      z-index: 99;
    }

    .sub-menu-name {
      padding: 4px 7px;
      border-radius: 6px;
      font-size: 16px;
      font-weight: 500;
      line-height: 30px;
      background-color: #FFFFFF00;
      cursor: pointer;

      &:hover {
        background-color: #F9F9F9;
      }

      &.selected {
        font-weight: 700;
        background-color: #F9F9F9;
      }
    }
  }

  .method-result-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
    border-radius: 12px;
    background-color: #EFEFEF;

    .method-result-text {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: -0.01em;
    }
  }

  .kit-select-network-button {
    color: #5888FF;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}
