@import "../../../styles/variable";
@import "../../../styles/_mixins";

.network-utilization-comparison {
  display: flex;
  align-items: center;
  height: 100%;
  min-height: 377px;
  max-height: 377px;

  .network-utilization-comparison-chart-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 377px;

    .network-utilization-comparison-chart {
      width: 100%;
      height: 314px;

      &-label {
        &-name {
          font-size: 14px;
          font-weight: 500;
          line-height: 18px;
        }

        &-percent {
          font-size: 12px;
          line-height: 15px;
        }
      }

      &-custom-legend {
        display: flex;
        justify-content: center;
        align-items: center;

        &-item {
          display: flex;
          align-items: center;

          &:not(:last-child) {
            margin-right: 5px;
          }

          &-icon {
            margin-right: 5px;
            width: 8px;
            height: 8px;
          }

          &-label {
            font-size: 16px;
            font-weight: 700;
            line-height: 21px;
            color: $fc-main;
          }
        }
      }
    }

    .network-utilization-comparison-chart-notice {
      font-size: 12px;
      line-height: 15px;
      color: $fc-main;
    }
  }
}
