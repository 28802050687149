@import "../../../../../styles/variable";

.airdrop-participant-detail-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;

  &-description {
    width: 420px;
    margin: 0 auto 40px;
    line-height: 21px;
    color: $fc-sub;
    white-space: pre-line;
  }

  &-information {
    &-row {
      margin: 0 0 20px;

      &:last-child {
        margin: 0;
      }

      &-label {
        margin: 0 0 10px;
        color: $fc-sub;
        font-weight: $fw-medium;
      }
    }
  }
  &-button-container {
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }
}
