.page-header {
  width: 100%;
  margin-bottom: 40px;
  padding: 40px 0;
  border-bottom: 2px solid #e7e9ef;

  &-title {
    color: #252a3f;
    font-size: 24px;
    font-weight: 700;
  }
}
