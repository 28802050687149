/* color refactor at 2023.1.12 */
$color-black: #3f4248;
$color-blue: #5888ff;
$color-skyblue: #e9efff;
$color-red: #cf7171;
$color-green: #6bc97e;
$color-white: #ffffff;
$color-dark-gray: #7d828d;
$color-gray: #b1b1c1;
$color-light-gray: #b7babe;
$color-blue-gradient: linear-gradient(91.68deg, #6773ff 0%, #47a0ff 100%);

$color-border-liner: #e7e9ef;
$color-border-dot: #b7babe;

$color-background: #f4f4f4;

$color-primary: #5888ff;
$color-secondary: #96b9fe;

$color-eqhub-default-background-color: #f4f6f8;

/* box-shadow */
$box-shadow-common: 0 1px 1px rgba(0, 0, 0, 0.05), 0 2px 5px rgba(61, 84, 128, 0.1);

/* font size */
$fs-page-title: 32px;
$fs-modal-title: 32px;
$fs-index: 18px;
$fs-normal: 16px;
$fs-comment: 14px;
$fs-small: 12px;

/* font size refactor at 2023.1.12 */
$fs-36: 36px;
$fs-20: 20px;
$fs-18: 18px;
$fs-16: 16px;
$fs-14: 14px;

/* font color */
$fc-main: #3f4248;
$fc-sub: #7d828d;
$fc-placeholder: #b1b1c1;

/* font weight */
$fw-light: 300;
$fw-regular: normal;
$fw-medium: 500;
$fw-bold: bold;
$fw-black: 800;

/* header component */
$header-height: 68px;

/* input component */
$input-height: 50px;
