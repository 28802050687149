@import "../../styles/variable";

#SNB {
  --user-tab-height: 68px;

  flex-direction: column;
  align-self: flex-start;
  justify-self: flex-start;
  width: 0;


  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
  }

  .SNB-content-wrapper {
    position: absolute;
    width: 0;
    top: 0;
    left: 0;
    height: 100vh;
    z-index: 1000;
    background-color: #fff;
    transition: all 0.3s ease-in-out;

    &.open {
      width: 240px;
    }

    .main-header-logo {
      display: flex;
      align-items: center;
      width: 100%;
      overflow: hidden;
      padding-left: 20px;
      padding-bottom: 2px;
      height: 68px;

      .main-header-menu {
        width: 24px;
        height: 24px;
        object-fit: contain;
        margin-right: 20px;
        cursor: pointer;
      }

      .main-header-logo-src {
        cursor: pointer;
        height: 24px;
        object-fit: contain;
      }
    }

    .nav-container {
      display: flex;
      flex-direction: column;
      overflow-x: visible;
      overflow-y: auto;
      height: calc(100vh - $header-height - var(--user-tab-height));

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}
