@import "../../../styles/variable";
@import "../../../styles/_mixins";

.requests-history-table-container {
  display: flex;
  align-items: center;
  height: 100%;
  min-height: 377px;
  max-height: 377px;
}
