.chart-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 288px;
}

.cude {
  --side-length: 1.5em;
  --animation-duration: 1.5s;

  margin: auto;
  font-size: 24px;
  height: var(--side-length);
  width: var(--side-length);
  position: relative;
  transform: rotatex(30deg) rotatey(45deg);
  transform-style: preserve-3d;
  animation: cube-spin var(--animation-duration) infinite ease-in-out alternate;

  .side {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transform-style: preserve-3d;
  }

  .side::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #2276ca;
    transform: translatez(var(--side-length) / 2);
    animation: cube-explode var(--animation-duration) infinite ease-in-out;
    opacity: 0.4;
  }

  .side:nth-child(1) {
    transform: rotatey(90deg);
  }

  .side:nth-child(2) {
    transform: rotatey(180deg);
  }

  .side:nth-child(3) {
    transform: rotatey(270deg);
  }

  .side:nth-child(4) {
    transform: rotatey(360deg);
  }

  .side:nth-child(5) {
    transform: rotatex(90deg);
  }

  .side:nth-child(6) {
    transform: rotatex(270deg);
  }

  @keyframes cube-spin {
    0% {
      transform: rotatex(30deg) rotatey(45deg);
    }

    100% {
      transform: rotatex(30deg) rotatey(405deg);
    }
  }

  @keyframes cube-explode {
    0% {
      transform: translatez(calc(var(--side-length) / 2));
    }

    50% {
      transform: translatez(calc(var(--side-length) * 0.7));
    }

    100% {
      transform: translatez(calc(var(--side-length) / 2));
    }
  }
}

.box-loading {
  --duration: 1s;
  --container-size: 180px;
  --box-size: 28px;
  --box-border-radius: 15%;

  width: var(--container-size);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  height: 100%;
}

.box-loading-item {
  width: var(--box-size);
  height: var(--box-size);
  position: relative;
  display: block;
  transform-origin: -50% center;
  border-radius: var(--box-border-radius);
}

.box-loading-item:after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  background-color: lightblue;
  border-radius: var(--box-border-radius);
  box-shadow: 0px 0px 10px 0px rgba(28, 159, 255, 0.4);
}

.box-loading-item:nth-child(1) {
  animation: slide var(--duration) ease-in-out infinite alternate;
}

.box-loading-item:nth-child(1):after {
  animation: color-change var(--duration) ease-in-out infinite alternate;
}

.box-loading-item:nth-child(2) {
  animation: flip-1 var(--duration) ease-in-out infinite alternate;
}

.box-loading-item:nth-child(2):after {
  animation: squidge-1 var(--duration) ease-in-out infinite alternate;
}

.box-loading-item:nth-child(3) {
  animation: flip-2 var(--duration) ease-in-out infinite alternate;
}

.box-loading-item:nth-child(3):after {
  animation: squidge-2 var(--duration) ease-in-out infinite alternate;
}

.box-loading-item:nth-child(4) {
  animation: flip-3 var(--duration) ease-in-out infinite alternate;
}

.box-loading-item:nth-child(4):after {
  animation: squidge-3 var(--duration) ease-in-out infinite alternate;
}

.box-loading-item:nth-child(5) {
  animation: flip-4 var(--duration) ease-in-out infinite alternate;
}

.box-loading-item:nth-child(5):after {
  animation: squidge-4 var(--duration) ease-in-out infinite alternate;
}

.box-loading-item:nth-child(2):after {
  background-color: #1c9fff;
}

.box-loading-item:nth-child(3):after {
  background-color: #1fb1fd;
}

.box-loading-item:nth-child(4):after {
  background-color: #22c7fb;
}

.box-loading-item:nth-child(5):after {
  background-color: #23d3fb;
}

@keyframes slide {
  0% {
    background-color: #1795ff;
    transform: translatex(0vw);
  }

  100% {
    background-color: #23d3fb;
    transform: translatex(calc(var(--container-size) - (var(--box-size) * 1.25)));
  }
}

@keyframes color-change {
  0% {
    background-color: #1795ff;
  }

  100% {
    background-color: #23d3fb;
  }
}

@keyframes flip-1 {
  0%,
  15% {
    transform: rotate(0);
  }

  35%,
  100% {
    transform: rotate(-180deg);
  }
}

@keyframes squidge-1 {
  5% {
    transform-origin: center bottom;
    transform: scalex(1) scaley(1);
  }

  15% {
    transform-origin: center bottom;
    transform: scalex(1.3) scaley(0.7);
  }

  25%,
  20% {
    transform-origin: center bottom;
    transform: scalex(0.8) scaley(1.4);
  }

  55%,
  100% {
    transform-origin: center top;
    transform: scalex(1) scaley(1);
  }

  40% {
    transform-origin: center top;
    transform: scalex(1.3) scaley(0.7);
  }
}

@keyframes flip-2 {
  0%,
  30% {
    transform: rotate(0);
  }

  50%,
  100% {
    transform: rotate(-180deg);
  }
}

@keyframes squidge-2 {
  20% {
    transform-origin: center bottom;
    transform: scalex(1) scaley(1);
  }

  30% {
    transform-origin: center bottom;
    transform: scalex(1.3) scaley(0.7);
  }

  40%,
  35% {
    transform-origin: center bottom;
    transform: scalex(0.8) scaley(1.4);
  }

  70%,
  100% {
    transform-origin: center top;
    transform: scalex(1) scaley(1);
  }

  55% {
    transform-origin: center top;
    transform: scalex(1.3) scaley(0.7);
  }
}

@keyframes flip-3 {
  0%,
  45% {
    transform: rotate(0);
  }

  65%,
  100% {
    transform: rotate(-180deg);
  }
}

@keyframes squidge-3 {
  35% {
    transform-origin: center bottom;
    transform: scalex(1) scaley(1);
  }

  45% {
    transform-origin: center bottom;
    transform: scalex(1.3) scaley(0.7);
  }

  55%,
  50% {
    transform-origin: center bottom;
    transform: scalex(0.8) scaley(1.4);
  }

  85%,
  100% {
    transform-origin: center top;
    transform: scalex(1) scaley(1);
  }

  70% {
    transform-origin: center top;
    transform: scalex(1.3) scaley(0.7);
  }
}

@keyframes flip-4 {
  0%,
  60% {
    transform: rotate(0);
  }

  80%,
  100% {
    transform: rotate(-180deg);
  }
}

@keyframes squidge-4 {
  50% {
    transform-origin: center bottom;
    transform: scalex(1) scaley(1);
  }

  60% {
    transform-origin: center bottom;
    transform: scalex(1.3) scaley(0.7);
  }

  70%,
  65% {
    transform-origin: center bottom;
    transform: scalex(0.8) scaley(1.4);
  }

  100%,
  100% {
    transform-origin: center top;
    transform: scalex(1) scaley(1);
  }

  85% {
    transform-origin: center top;
    transform: scalex(1.3) scaley(0.7);
  }
}
