@import "../../../../../styles/variable";

.network-delete-modal {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 565px;
  min-width: 565px;

  &-description {
    line-height: 21px;
    color: $fc-sub;
    text-align: center;
  }

  &-row {
    display: flex;
    flex-direction: column;
    gap: 10px;

    &-title {
      display: flex;
      align-items: center;
      gap: 10px;

      &-dot {
        width: 5px;
        height: 5px;
        background-color: #b1b1c1;
      }

      &-label {
        font-weight: 500;
        color: #848a96;
      }
    }
  }

  &-button-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
}
