@import "../../../styles/variable";
@import "../../../styles/_mixins";

.most-used-requests {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 357px;
  max-height: 357px;

  .total-request {
    display: flex;
    align-items: end;
    font-size: 24px;
    font-weight: 700;
    color: $color-primary;

    &-unit {
      margin-left: 5px;
      font-weight: 500;
      font-size: 16px;
    }
  }

  .most-used-requests-chart {
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
    color: $fc-sub;
    height: 250px;

    &-custom-tooltip {
      border: 2px solid $color-blue;
      border-radius: 8px;
      background-color: #fff;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
      box-sizing: border-box;
      padding: 5px 10px;

      &-item {
        display: flex;
        align-items: center;

        &-block {
          margin-right: 5px;
          width: 8px;
          height: 8px;
        }

        &-label {
          font-size: $fw-medium;
          font-size: 13px;
          line-height: 17px;
          color: $fc-main;
        }
      }
    }

    &-custom-legend {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 18px;

      &-item {
        position: relative;
        display: flex;
        align-items: center;

        &:hover {
          &::after {
            content: attr(data-legend-key);
            position: absolute;
            top: 20px;
            left: 0;
            padding: 5px 10px;
            width: fit-content;
            font-weight: 500;
            white-space: nowrap;
            color: $fc-main;
            background-color: #fff;
            box-shadow: 0 2px 5px #3d548025;
            border: 2px solid #7ea3ff;
            border-radius: 8px;
          }
        }

        &:not(:last-child) {
          margin-right: 5px;
        }

        &-icon {
          margin-right: 5px;
          width: 8px;
          height: 8px;
        }

        &-label {
          font-size: 16px;
          font-weight: 700;
          line-height: 21px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          color: $fc-main;
          max-width: 100px;
        }
      }
    }
  }

  &-options {
    display: flex;
    align-items: center;
    gap: 12px;
    width: 100%;

    &-item {
      padding: 8px 14px;
      font-size: 12px;
      font-weight: 500;
      color: #7f879e;
      border-radius: 12px;
      border: 1px solid #efefef;
      background-color: #fff;
      transition: 0.15s ease-in-out;
      cursor: pointer;

      &.selected {
        color: #f9f9f9;
        border: 1px solid $color-primary;
        background-color: $color-primary;
      }
    }
  }
}
