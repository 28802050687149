@import "../../../styles/variable";

.pricing-plan {
  display: flex;
  flex-direction: column;
  padding: 36px 9.5px;
  border: 1px solid #efefef;
  border-radius: 16px;
  &-top {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 397px;
    padding-bottom: 30px;
    &-description {
      max-width: 236px;
      height: 48px;
      line-height: 15.6px;
      font-weight: 500;
      font-size: 12px;
      text-align: center;
      color: #7f879e;
      white-space: pre-line;
    }
    &-name {
      line-height: 26px;
      font-weight: 700;
      font-size: 20px;
      text-align: center;
      color: #7f879e;
    }
    &-price {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 18px;
      &-image-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;
        margin-right: 5px;
        border: 1px solid #d9d9d9;
        border-radius: 50%;
      }
      &-image {
        width: 19.5px;
        object-fit: contain;
      }
      &-text {
        color: #252a3f;
        font-weight: 700;
        font-size: 32px;
        line-height: 41.6px;
      }
      &-sub-text {
        line-height: 25.6px;
        color: #252a3f;
        font-size: 16px;
        text-align: center;
      }
    }
    &-button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 236px;
      height: 40px;
      background-color: $color-primary;
      border-radius: 12px;
      font-size: 14px;
      font-weight: 500;
      color: #ffffff;
      cursor: pointer;

      &.selected {
        background-color: #7f879e;
        cursor: default;
      }
    }

    &-dollar {
      color: #252a3f;
      font-size: 16px;
    }
  }
  &-bottom {
    padding: 30px 12px 0 12px;
    border-top: 1px solid #d8d8d8;

    &-title {
      margin-bottom: 11px;
      font-weight: 500;
      color: #7f879e;
    }
    &-item {
      display: flex;
      align-items: center;
      margin-top: 6px;
      font-size: 14px;
      line-height: 22px;
      color: #7f879e;
      &-image {
        width: 10px;
        margin-right: 10px;
        object-fit: contain;
      }
    }
  }
}
