@import "../../../styles/mixins";

#UserProfile {
  display: flex;
  flex-direction: column;
  width: 1200px;
  margin: 0 auto;
  padding: 0 50px;

  .user-profile-container {
    width: 812px;

    .user-profile-button {
      padding: 8px 14px;
      font-weight: 500;
      size: 14px;
      line-height: 1.3;
      color: $color-primary;
      background-color: #eaeffe;
      border-radius: 8px;
    }
  }
}

.user-profile-mfa-announcement {
  width: 302px;
}

.multi-factor-authentication {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px 12px;

  &-info {
    display: flex;
    align-items: center;
    gap: 10px;

    &-type {
      display: flex;
      align-items: center;
      gap: 7px;
      border: 1px solid $color-primary;
      border-radius: 15px;
      padding: 3px 10px;

      &-icon {
        width: 14px;
        object-fit: contain;
      }

      &-label {
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: $color-primary;
      }
    }
  }

  &-delete {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 18px;
    height: 18px;

    &-icon {
      width: 100%;
      object-fit: contain;
      cursor: pointer;
    }
  }
}
