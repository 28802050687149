@import "../../../../../styles/variable";

.airdrop-contract-deploy-modal {
  &-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 50px;

    &:not(:last-child) {
      margin-bottom: 25px;
    }
    &-label {
      font-weight: $fw-medium;
      font-size: 16px;
      line-height: 19px;
      color: $fc-main;
    }
    &-data {
      font-weight: 400;
      font-size: 16px;
      line-height: 21px;
      color: $fc-main;
    }
  }
}
