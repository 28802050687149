@import "../../../styles/variable";

#ProjectBilling {
  display: flex;
  flex-direction: column;
  width: 1200px;
  margin: 0 auto;
  padding: 0 50px;

  .project-billing-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    .project-billing {
      &-payment-information {
        display: flex;
        justify-content: space-between;
        padding: 24px;
        width: 100%;
        background-color: #ffffff;
        border-radius: 16px;

        &-left {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: 310px;
          padding: 10.5px 0;
          &-title {
            margin-bottom: 12px;
            font-size: 24px;
            font-weight: 700;
            line-height: 31px;
          }
          &-description {
            font-size: 14px;
            font-weight: 500;
            line-height: 18px;
            white-space: pre-line;
          }
        }
        &-right {
          display: flex;
          align-items: center;
          padding: 26px 0 14px;
          border-radius: 16px;
          border: 1.5px solid #efefef;
          &-item {
            display: flex;
            align-items: center;
            width: 176.5px;
            padding-left: 19px;
            &-label {
              display: flex;
              align-items: center;
              font-size: 14px;
              line-height: 22px;
              color: #7f879e;
              &-image {
                width: 18px;
                margin-left: 8px;
                object-fit: contain;
                cursor: pointer;
              }
            }
            &-value {
              display: flex;
              align-items: center;
              height: 30px;
              margin-top: 8px;
              font-size: 14px;
              font-weight: 500;
              line-height: 18px;
              color: #252a3f;
              &-image {
                width: 18px;
                margin-right: 8px;
                object-fit: contain;
              }
            }
            &-divide-line {
              width: 2px;
              height: 32px;
              background-color: #f3f3f3;
            }
          }
        }
      }

      &-estimated-cost {
        &-total-cost {
          display: flex;
          align-items: center;
          margin-top: 20px;
          padding: 10px 24px;
          border: 1px solid $color-primary;
          border-radius: 16px;
          &-label {
            width: 50%;
            font-size: 16px;
            font-weight: 500;
            line-height: 21px;
            color: $fc-main;
          }
          &-value {
            font-size: 24px;
            font-weight: 500;
            line-height: 31px;
            color: $color-primary;
          }
        }

        &-progress {
          &-bar {
            display: flex;
            width: 100%;
            height: 20px;
            border-radius: 10px;
            background-color: #d9d9d9;
            overflow: hidden;

            &-item {
              height: 100%;
            }
          }

          &-amount {
            display: flex;
            width: 100%;

            &-item {
              display: flex;
              flex-direction: column;
              align-items: center;
              font-size: 16px;
              font-weight: 700;
              line-height: 21px;
              white-space: nowrap;

              &-bar {
                width: 1px;
                height: 12px;
                background-color: #b1b1c1;
              }
            }
          }

          &-name {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 10px 0;

            &-item {
              display: flex;
              align-items: center;
              margin-right: 10px;
              font-size: 14px;
              font-weight: 700;
              line-height: 18px;
              color: $fc-main;

              &-label {
                width: 8px;
                height: 8px;
                margin-right: 5px;
              }
            }
          }

          &-description {
            margin-bottom: 20px;
            font-size: 12px;
            line-height: 15px;
            color: #7d828d;
          }
        }
      }

      &-row {
        display: flex;
        align-items: center;
        padding: 5px 10px;
        border-bottom: 1px solid #efefef;

        &-label {
          width: 50%;
          font-weight: 700;
          font-size: 14px;
          color: #1b2124;
        }

        &-value {
          display: flex;
          align-items: center;
          font-weight: 500;
          font-size: 16px;
          color: #252a3f;
        }
      }
    }
  }
}

.credit-image-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border: 1px solid #d9d9d9;
  border-radius: 50%;
  margin-right: 12px;
}

.credit-image {
  width: 19.5px;
  object-fit: contain;
}
