@import "../../../../view/styles/variable";

.select {
  position: relative;
  flex: 1;
  width: 100%;

  .select-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 50px;
    padding: 14px 20px;
    border: 2px solid $color-border-liner;
    border-radius: 8px;
    background-color: $color-white;
    font-size: $fs-normal;

    .select-value {
      font-weight: $fw-regular;

      &.placeholder {
        color: $fc-placeholder;
      }
    }

    .select-arrow {
      width: 10px;
      object-fit: contain;
    }

    &:disabled {
      display: flex;
      align-items: center;
      padding: 0;
      border: none;
      font-size: $fs-normal;
      background-color: transparent;
      color: $fc-main;
      cursor: default;
    }
  }

  .select-description {
    position: absolute;
    line-height: 15px;
    font-size: $fs-small;
    font-weight: $fw-medium;
    color: $fc-placeholder;
  }

  .select-option-panel {
    position: absolute;
    // width: calc(100% - 20px);
    max-width: 100%;
    width: 100%;
    max-height: 300px;
    overflow-y: scroll;
    padding: 10px 10px 10px 10px;
    top: 54px;
    // left: 50%;
    // transform: translate(-50%, 0%);
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    background-color: $color-white;
    z-index: 99;

    &::-webkit-scrollbar {
      width: 10px;
      height: 8px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 20px;
      background-color: #dddddd;
    }

    .option-panel-label {
      margin: 0 0 2px 0;
      padding: 10px 10px;
      cursor: pointer;
      border-radius: 4px;
      transition: 0.2s;

      &:hover,
      &.selected {
        background-color: #f5f5f5;
      }

      &:last-child {
        margin: 0;
      }
    }
  }
}
