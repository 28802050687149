@import "../../../styles/variable";
@import "../../../styles/mixins";

.section-container {
  padding: 24px;
  border-radius: 16px;
  background-color: #fff;
  width: 100%;

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-info {
      display: flex;
      align-items: center;

      &-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
        width: 26px;
        height: 26px;
        background-color: #e4e6eb;
        border-radius: 100%;

        &-src {
          width: 18px;
          object-fit: contain;
        }
      }

      &-title {
        margin-right: 10px;
        font-weight: $fw-bold;
        font-size: 16px;
        line-height: 21px;
        color: #252a3f;
      }
    }
  }

  &-body {
    margin-top: 20px;
  }
}
