@import "../../../../../styles/variable";

.airdrop-operating-way-purpose-box {
  flex: 1;
  padding: 24px 16px;
  border: 1px solid #ececec;
  border-radius: 8px;
  background: radial-gradient(100% 250% at 0% 0%, #fff 0%, #f5f8fd 100%);
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.05);
  transition: 0.15s ease-in-out;
  cursor: pointer;

  &.disabled {
    cursor: default;
  }

  &.selected {
    border: 1px solid $color-blue;
  }
  &.not-selected {
    filter: grayscale(1);

    .airdrop-operating-way-purpose-content .airdrop-operating-way-purpose-label,
    .airdrop-operating-way-purpose-content .airdrop-operating-way-purpose-description,
    .airdrop-operating-way-purpose-content .airdrop-operating-way-purpose-data {
      color: $fc-placeholder;
    }
  }

  &.enabled:hover {
    border: 1px solid $color-blue;
    filter: grayscale(0);
  }

  .airdrop-operating-way-purpose-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 198px;

    .airdrop-operating-way-purpose-label {
      font-weight: $fw-bold;
      font-size: 30px;
      color: $fc-main;
    }

    .airdrop-operating-way-purpose-description {
      font-size: $fs-comment;
      text-align: center;
      color: $fc-sub;
    }

    .airdrop-operating-way-purpose-data {
      display: flex;
      flex-direction: column;
      font-size: $fs-normal;
      line-height: 21px;
      color: #848a96;

      &-row {
        list-style: square;
        font-weight: $fw-medium;
        line-height: 1.34;

        &::marker {
          color: $color-blue;
        }
      }
    }
  }
}
