.array-parameter-input-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  .array-parameter-input-row{
    display: flex;
    &:not(&:last-child){
      margin-bottom: 10px;
    }
  }
  .add-parameter-icon{
    margin-left: 10px;
    width: 24px;
    object-fit: contain;
    cursor: pointer;
  }
}
