.contract-info {
  display: flex;
  justify-content: space-between;

  padding: 40px;
  border-radius: 16px;
  background-color: #fff;
  .contract-info-left {
    display: flex;
    flex-direction: column;
    gap: 30px;
    max-width: 446px;
    .contract-info-title {
      margin-bottom: 10px;
      font-size: 28px;
      font-weight: 700;
      line-height: 36px;
      color: #1d1f22;
    }
    .contract-info-description {
      margin-bottom: 10px;
      line-height: 24px;
      white-space: pre-line;
      color: #7f879e;
    }
    .contract-info-detail {
      display: flex;
      align-items: center;
      gap: 20px;

      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      color: #aaafbe;
      .contract-info-detail-box {
        display: flex;
        align-items: center;
        gap: 4px;
        .contract-info-detail-data {
          color: #3d425a;
        }
      }
    }
    .contract-info-sub-title {
      margin-bottom: 7px;
      font-size: 14px;
      font-weight: 600;
      line-height: 18px;
      color: #7f879e;
    }
    .contract-info-version-box {
      color: #7f879e;

      .select {
        width: 132px;
        margin-bottom: 10px;
        .select-button {
          height: 34px;
          border: 1px solid #d9d9d9;
          border-radius: 30px;
        }
        .select-options {
          position: absolute;
          width: 100%;
          top: 34px;
          padding: 16px 0;
          border-radius: 16px;
          background-color: #fff;
            box-shadow: 0px 4px 30px 0px #7F879E26;
          
          .option-panel-label {
          padding: 10px;
            border-radius: 0;
            border-top: 1px solid #efefef;
            border-bottom: 1px solid #efefef;

            &.selected {
              background-color: #f9f9f9;
            }
          }
        }
      }
      .contract-info-version{
        margin-bottom: 10px;
      }
      .contract-info-version-description {
        font-size: 12px;
        line-height: 16px;
      }
    }
    .contract-info-left-btm {
      display: flex;
      flex-direction: column;
      gap: 30px;
      .contract-info-type {
        .contract-info-type-box {
          display: flex;
          align-items: center;
          gap: 5px;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          .type-solution {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 26px;
            padding: 0 10px;
            border: 1px solid #5888ff;
            border-radius: 17px;
            color: #5888ff;
          }
          .type-standard {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 26px;
            padding: 0 10px;
            border-radius: 17px;
            background-color: #e4e6fc;
            color: #7673ff;
          }
        }
      }
    }
  }
  .contract-info-right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    .contract-info-right-img-box {
      width: 200px;
      img {
        width: 100%;
        border-radius: 34px;
      }
    }
    .contract-info-right-btns {
      display: flex;
      align-items: center;
      gap: 6px;
      .contract-info-right-btn-share {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        border-radius: 8px;
        border: 1px solid #d8d8d8;
        box-shadow: 0px 2px 20px 0px #ffffff29;
        cursor: pointer;
        img {
          width: 24px;
          height: 24px;
        }
      }
      .contract-info-right-btn-get {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 200px;
        height: 40px;
        border-radius: 8px;
        background-color: #5888ff;
        font-size: 14px;
        font-weight: 500;
        color: #fff;
        cursor: pointer;

      }
    }
  }
}
