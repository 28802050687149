.coin-image-selector {
  width: 130px;
  height: 130px;
  border-radius: 100%;
  border: 2px dashed #e7e9ef;
  background-color: #fff;
  overflow: hidden;
  cursor: pointer;

  input[type="file"] {
    display: none;
  }

  &-preview {
    width: 100%;
    height: 100%;
    object-fit: cover;

    &.able {
      cursor: pointer;
    }
  }

  &-default {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
    width: 100%;
    height: 100%;
    transition: 0.15s;

    &:hover {
      background-color: #efefef;
    }

    &-icon {
      width: 30px;
      object-fit: contain;
    }

    &-label {
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: #b1b1c1;
    }
  }
}
