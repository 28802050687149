@import "../../../../../styles/variable";
@import "../../../../../styles/mixins";

.pre-allocation-button-wrapper {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.pre-allocation-table-distribution {
  &-percent {
    color: $fc-main;
  }

  &-amount {
    font-size: 14px;
    color: #6e737d;
    word-break: break-all;
    white-space: pre-line;
  }
}

.pre-allocation-visualization-chart {
  display: flex;
  flex-direction: column;
  align-items: center;

  &-label {
    font-size: $fs-small;
  }

  &-name {
    font-weight: $fw-medium;
  }

  &-percent {
    font-size: 10px;
    font-weight: $fw-regular;
  }
}

.pre-allocation-field-initial-supply {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 3px;
  font-weight: 500;

  &-value {
    font-size: 18px;
  }

  &-symbol {
    font-size: 14px;
  }
}

.pre-allocation-field-allocated {
  &-value-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    flex: 1;
    font-weight: 500;

    &.over {
      color: $color-red;
    }

    &.enough {
      color: $color-green;
    }

    &.insufficient {
      color: $color-blue;
    }

    .pre-allocation-field-allocated-percent {
      font-size: 22px;
      line-height: 28px;
    }

    .pre-allocation-field-allocated-value {
      font-size: 14px;
      line-height: 18px;
    }
  }

  &-status {
    width: 100%;
    text-align: center;
    font-weight: 500;
    line-height: 21px;

    &.over {
      color: $color-red;
    }

    &.enough {
      color: $color-green;
    }

    &.insufficient {
      color: $color-blue;
    }
  }
}

.pre-allocation-table {
  box-sizing: border-box;
  width: 100%;
  border-collapse: separate !important;
  font-size: $fs-normal;
  color: $fc-main;
  border-radius: 20px;
  box-shadow: (0px 1px 1px rgba(0, 0, 0, 0.05)), (0px 2px 5px rgba(61, 84, 128, 0.1));
  table-layout: fixed;
  background-color: $color-white;

  thead {
    tr {
      color: #33373b;
      background-color: #f4f6f8;
      height: 55px;

      th {
        vertical-align: middle;
        cursor: pointer;

        &:first-child {
          border-top-left-radius: 20px;
        }

        &:last-child {
          border-top-right-radius: 20px;
        }
      }
    }
  }

  tbody {
    display: block;
    width: 752px;
    letter-spacing: -0.32px;
    font-weight: normal;

    tr {
      display: table;
      width: 100%;
      height: 109px;

      &:last-child {
        td {
          &:first-child {
            border-bottom-left-radius: 20px;
          }

          &:last-child {
            border-bottom-right-radius: 20px;
          }
        }
      }

      td {
        padding: 0 11px;
        text-align: center;
        vertical-align: middle;
        border-bottom: 1px solid $color-border-liner;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        &:first-child {
          min-width: 233.12px;
          max-width: 233.12px;
          width: 233.12px;
        }

        &:nth-child(2) {
          min-width: 233.12px;
          max-width: 233.12px;
          width: 233.12px;
        }

        &:nth-child(3) {
          min-width: 233.12px;
          max-width: 233.12px;
          width: 233.12px;
        }

        &:nth-child(4) {
          min-width: 52.65px;
          max-width: 52.65px;
          width: 52.65px;
        }
      }

      &:first-child {
        td {
          border-top: 1px solid $color-border-liner;
        }
      }

      &:last-child {
        td {
          border-bottom: none;
        }
      }

      .node-distribution {
        display: flex;
        font-size: $fs-comment;

        &-percent {
          margin-left: 5px;
        }

        &-amount {
          margin-left: 5px;
          color: #6e737d;

          &-balance {
          }

          &-symbol {
            font-size: $fs-small;
          }
        }
      }
    }
  }

  .table-no-data {
    cursor: default;
    color: $fc-main;
    font-weight: $fw-medium;
    font-size: $fs-14;
  }

  .alloc-row-delete {
    width: 16px;
    object-fit: contain;
    cursor: pointer;
  }
}
