@import "../../../view/styles/variable";

.text-area {
  position: relative;
  width: 100%;
  flex: 1;
  .atom-textarea {
    width: 100%;
    padding: 14px 20px;
    border-radius: 8px;
    border: solid 2px $color-border-liner;
    background-color: $color-white;
    font-size: $fs-normal;
    color: $fc-main;
    resize: none;
    &::placeholder {
      color: $fc-placeholder;
    }
    &:focus {
      outline: none;
    }
    &:read-only {
      padding: 0;
      height: auto;
      outline: none;
      border: none;
      border-radius: 0;
      background-color: transparent;
      resize: none;
    }
    &::-webkit-scrollbar {
      width: 10px;
      height: 8px;
    }
    /* Add a thumb */
    &::-webkit-scrollbar-thumb {
      border-radius: 20px;
      background-color: #dddddd;
    }
  }
  .textarea-invalid-comment {
    position: absolute;
    left: 20px;
    bottom: calc(0px - (#{$fs-comment} + 5px));
    font-size: $fs-comment;
    font-weight: $fw-medium;
    color: #de1f1f;
  }
}
