#EnterPinCodeModal {
  .pin-code-circle-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;
    width: 100%;
    padding: 50px 40px;

    .pin-code-circle {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background-color: #D8D8D8;

      &.active {
        background-color: #5888FF;
      }
    }
  }

  .pin-code-button-wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 4px;
    width: 100%;
    padding: 4px;
    border-radius: 4px;
    background-color: #D8E1EE;

    .pin-code-button {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      font-size: 20px;
      font-weight: 500;
      color: #3F4248;
      background-color: #FFFFFF;
      border-radius: 3px;
      cursor: pointer;

      &:hover {
        background-color: #F0F0F0;
      }

      &.logo {
        background-color: transparent;

        img {
          width: 30px;
          height: 16px;
          object-fit: contain;
        }
      }

      &.clear {
        //우측 하단
        grid-area: 4 / 1 / 5 / 3;
        color: #8D9092;
        font-size: 16px;
      }

      &.delete {
        //우측 하단
        grid-area: 4 / 3 / 5 / 5;

        img {
          width: 24px;
          height: 24px;
          object-fit: contain;
        }
      }
    }
  }
}
