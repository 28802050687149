.kit-container {
  display: flex;
  flex-direction: column;
  gap: 30px;
  position: relative;
  width: 100%;
  padding: 30px;
  border-radius: 16px;
  border: 1px solid #ECECEC;
  background-color: #FFFFFF;

  .kit-container-title {
    margin-bottom: 4px;
    font-size: 16px;
    font-weight: 700;
    line-height: 20.8px;
  }

  .kit-container-description {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
  }
}
