.question-mark {
  position: relative;

  &.PRIMARY {
    .question-mark-area {
      width: 16px;
      height: 16px;
      background-color: #efefef;

      &-label {
        font-size: 12px;
        color: #8d9092;
      }
    }
  }
  &.SECONDARY {
    .question-mark-area {
      width: 12px;
      height: 12px;
      background-color: transparent;
      border: 1px solid #b1b1c1;

      &-label {
        font-size: 10px;
        color: #b1b1c1;
      }
    }
  }
  &-area {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    cursor: pointer;

    &-label {
      font-weight: 600;
    }

    &-tooltip {
      position: absolute;
      left: 24px;
      top: -14px;
      padding: 14px;
      min-width: 196px;
      background-color: #45516c;
      border-radius: 8px;
      z-index: 9999;

      &-area {
        position: relative;
        width: 100%;
        height: 100%;
        color: #b1b1c1;
        font-size: 12px;
        font-weight: 700;
        white-space: pre-line;

        &::before {
          content: "";
          position: absolute;
          border-right: 8px solid #45516c;
          border-top: 6px solid transparent;
          border-bottom: 6px solid transparent;
          top: 0px;
          left: -22px;
        }
      }
    }
  }
}
