@import "../../../../styles/variable";

.manage-project {
  display: flex;
  flex-direction: column;
  padding: 50px;
  height: 100%;
  letter-spacing: 0.005em;
  color: #11142d;

  &-header {
    margin-bottom: 20px;

    &-title-wrapper {
      display: flex;
      justify-content: space-between;

      .manage-project-quit-wrapper {
        display: flex;
        height: fit-content;
        cursor: pointer;

        &:hover {
          .manage-project-quit-text {
            color: $fc-main;
          }

          .manage-project-quit-icon {
            display: flex;
            filter: grayscale(0%);
          }
        }

        .manage-project-quit-text {
          font-weight: 500;
          font-size: 16px;
          line-height: 120%;
          letter-spacing: 0.005em;
          color: #7d828d;
        }

        .manage-project-quit-icon {
          margin-left: 8px;
          width: 20px;
          height: 20px;
          object-fit: contain;
          filter: grayscale(100%);
        }
      }
    }

    &-title {
      margin-bottom: 20px;
      font-weight: 700;
      font-size: 31px;
      line-height: 37px;
    }

    &-description {
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;
      white-space: pre-line;
    }
  }

  &-option {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;

    &-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex: 1;
      height: 100%;

      &-image {
        margin-bottom: 20px;
        height: 315px;

        &-src {
          height: 100%;
          object-fit: contain;
        }
      }

      &-label {
        margin-bottom: 20px;
        font-weight: 700;
        font-size: 24px;
        line-height: 28px;
      }

      &-description {
        margin-top: 20px;
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
      }
    }
  }
}
