.network-basic-info-coin-image-selector {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  width: 130px;
  height: 130px;
  border-radius: 100%;
  border: 2px dashed #e7e9ef;
  background-color: #fff;
  cursor: pointer;

  &-preview {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &-icon {
    width: 30px;
    object-fit: contain;
  }

  &-label {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #b1b1c1;
  }
}
