#TokenKitList {
  .kit-title {
    margin-bottom: 40px;

    font-size: 22px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: -0.03em;
    color: #252A3F;
  }

  .kit-list {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .kit-card {
      display: flex;
      width: 100%;
      padding: 20px;
      border-radius: 16px;
      background-color: #FFFFFF;
      cursor: pointer;
      transition: all 0.2s;

      &:hover {
        transform: translateY(-5px);
        box-shadow: 0 2px 5px 0 #3D54801A;
      }

      .kit-card-image {
        width: 60px;
        height: 60px;
        border-radius: 30px;
        border: 2px solid #E7E9EF;
        object-fit: contain;
        margin-right: 10px;
      }

      .kit-card-content {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: center;
        gap: 4px;

        .kit-card-name {
          max-width: 800px;
          font-weight: 500;
          font-size: 24px;
          line-height: 30px;
          color: #3F4248;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .kit-card-label {
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          color: #7D828D;
        }

        .kit-card-value {
          font-weight: 500;
          font-size: 20px;
          line-height: 24px;
          color: #3F4248;
        }
      }
    }

    .token-card {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 20px;
      border-radius: 16px;
      background-color: #FFFFFF;
      cursor: pointer;
      transition: all 0.2s;

      &:hover {
        transform: translateY(-5px);
        box-shadow: 0 2px 5px 0 #3D54801A;
      }

      .token-card-image {
        width: 60px;
        height: 60px;
        border-radius: 30px;
        border: 2px solid #E7E9EF;
        object-fit: contain;
        margin-right: 10px;
      }

      .token-card-content {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: center;
        gap: 4px;

        .token-card-name {
          max-width: 800px;
          font-weight: 500;
          font-size: 24px;
          line-height: 28px;
          color: #3F4248;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .token-card-label {
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          color: #7D828D;
        }

        .token-card-value {
          max-width: 400px;

          font-weight: 500;
          font-size: 20px;
          line-height: 24px;
          color: #3F4248;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    .kit-load-more {
      box-sizing: content-box;
      display: flex;
      align-self: center;
      justify-content: center;
      align-items: center;
      width: fit-content;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: #5888FF;
      border-bottom: 2px solid #5888FF00;

      transition: all 0.1s;

      cursor: pointer;

      &:hover {
        font-weight: 600;
        border-bottom-color: #5888FF;
      }

      .kit-load-more-icon {
        margin-left: 5px;
        width: 14px;
        height: 14px;
        object-fit: contain;
        fill: #5888FF;
      }
    }
  }

}


.select-kit-type-modal-content {
  display: flex;
  gap: 10px;
  margin-bottom: 30px;

  .select-kit-type-modal-content-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 356px;
    padding: 40px;
    border-radius: 16px;
    background-color: #E5E8EE80;

    transition: all 0.3s ease;
    cursor: pointer;

    &:hover {
      background-color: #C1CBE280;
    }

    &:active {
      background-color: #A5B5DE80;
    }

    .select-kit-type-modal-content-img {
      width: 160px;
      height: 160px;
      object-fit: contain;
      margin-bottom: 20px;
    }

    .select-kit-type-modal-content-title {
      margin-bottom: 10px;
      font-weight: 500;
      font-size: 22px;
      line-height: 26px;
    }

    .select-kit-type-modal-content-description {
      font-size: 14px;
      line-height: 16px;
      text-align: center;
    }
  }
}

.public-kits-modal {
  padding: 40px;

  .public-kits-modal-header {
    margin-bottom: 20px;
    font-size: 28px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: -0.03em;
    color: #1D1F22;
  }

  .public-kits-modal-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 600px;
    min-height: 600px;
  }
}
