.warning {
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 14px;
  border-radius: 16px;
  background-color: #ffffff;
  .warning-header {
    display: flex;
    align-items: center;
    gap: 8px;
    img {
      width: 24px;
      height: 24px;
      object-fit: contain;
    }
    .warning-title {
      color: #252a3f;
      font-size: 16px;
      font-weight: 700;
      line-height: 130%;
    }
  }
  .warning-description {
    color: #7f879e;
    font-size: 14px;
    font-weight: 400;
    line-height: 160%; /* 22.4px */
  }
}
