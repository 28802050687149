@import "../../../../view/styles/variable";

.color-control-wrapper {
  position: relative;
  display: flex;
  align-items: center;

  .color-box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 73px;
    height: 40px;
    cursor: pointer;
    background: $color-white;

    border: 2px solid $color-white;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05), 0px 2px 5px rgba(61, 84, 128, 0.1);
    border-radius: 25px;

    .color-paint {
      width: 63px;
      height: 30px;
      border: 1px solid $color-border-liner;
      border-radius: 17px;
    }
  }

  .color-picker-wrapper {
    position: absolute;
    bottom: 12px;
    left: 48px;
  }
}
