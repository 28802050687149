.tab-content-wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding: 40px 40px 80px;
  gap: 20px;
  height: 100%;
  //height: calc(100vh - 282px - var(--txHashHeight));
  //overflow: auto;
}

.icon-copy {
  width: 20px;
  height: 20px;
  object-fit: contain;
  cursor: pointer;

  stroke: #ACC4FF;

  &:hover {
    stroke: #5888FF;
  }
}

.container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;

  &.no-padding {
    padding: 0;
  }

  &.row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @media (max-width: 767px) {
      flex-direction: column;
      align-items: center;
    }
  }

  &.row-reverse {
    display: flex;
    flex-direction: row;
    justify-content: center;
    @media (max-width: 767px) {
      flex-direction: column-reverse;
      align-items: center;
    }
  }

  @media (min-width: 768px) {
    width: 750px;
  }
  @media (min-width: 992px) {
    width: 970px;
  }
  @media (min-width: 1200px) {
    width: 1200px;
  }
}
