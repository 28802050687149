.contract-detail-tabs-box{
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 20px;
  font-weight: 500;
  .contract-detail-tab{
    border: 1px solid #efefef;
    border-radius: 12px;
    padding: 8px 14px;
    background-color: #fff;
    color: #7F879E;
    cursor: pointer;
    &.selected{
      background-color:#5888FF;
      color: #fff;
    }
  }
}
.detail{
  .manage-field-container{
    margin-top: 20px;
  }
}

// Question Mark Announcement
.announcement-box{
  width: 338px;
}