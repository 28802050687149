.airdrop-operating-way {
  &-distribution-type {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &-payment-processing-system {
    display: flex;
    gap: 20px;
    width: 100%;
  }

  &-period-type {
    display: flex;
    gap: 20px;
    width: 100%;
  }

  &-period-date {
    display: flex;
    align-items: center;
    gap: 5px;
    max-width: 482px;
  }
}
