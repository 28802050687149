@import "../../../view/styles/variable";

.sign-in-expired-modal {
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 560px;

  &-body {
    display: flex;
    flex-direction: column;
    gap: 14px;

    &-row {
      font-size: 16px;
      color: #000;
      word-break: keep-all;

      &-contact {
        color: $color-primary;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  &-button-wrapper {
    display: flex;
    justify-content: center;
  }
}
