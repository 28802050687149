@import "../../../styles/variable";

.tab-section {
  padding: 8px 0;

  &-name {
    padding: 8px 15px;
    color: #d8d8d8;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.3;
    white-space: nowrap;
    overflow: hidden;
  }

  &-menu-container {
    padding: 2px 6px;

    &:hover > .tab-section-menu {
      background-color: #f9f9f9;
    }

    .tab-section-menu {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 7px;
      border-radius: 6px;
      transition: 0.15s ease-in-out;
      cursor: pointer;

      &.selected {
        background-color: #f9f9f9;
      }

      .tab-section-menu-wrapper {
        display: flex;
        align-items: center;

        .tab-section-menu-icon {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 10px;
          min-width: 24px;
          max-width: 24px;
          min-height: 24px;
          max-height: 24px;
          border-radius: 100%;
          background-color: #e4e6eb;

          &-src {
            width: 18px;
            object-fit: contain;
          }
        }

        .tab-section-menu-name {
          font-size: 14px;
          line-height: 1.6;
          color: #252a3f;
          white-space: nowrap;
          overflow: hidden;
        }
      }

      .tab-section-menu-multi-arrow {
        width: 14px;
        height: 14px;

        &.active {
          transform: rotate(180deg);
        }

        &-src {
          width: 100%;
          object-fit: contain;
        }
      }
    }

    .tab-section-sub-menu {
      padding: 2px 6px;
      cursor: pointer;

      &-item {
        padding: 8px 0 8px 27px;
        color: #252a3f;
        font-size: 14px;
        line-height: 1.6;

        &.selected {
          color: $color-primary;
          font-weight: 500;
        }
      }
    }
  }
}
