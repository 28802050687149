@import "../../../styles/variable";
@import "../../../styles/mixins";

.manage-field-container {
  margin-bottom: 20px;
  padding: 24px;
  border-radius: 16px;
  background-color: #fff;

  &:last-child {
    margin-bottom: 0;
  }

  .manage-field-header {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 20px;

    &-info-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &-info {
      display: flex;
      align-items: center;
      gap: 10px;
      min-height: 26px;

      &-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 26px;
        height: 26px;
        border-radius: 100%;
        background-color: #e4e6eb;

        &-src {
          width: 18px;
          object-fit: contain;
        }
      }

      &-title {
        font-size: 16px;
        color: #252a3f;
        font-weight: 700;
      }

      &-announcement {
      }
    }

    &-description {
      font-size: 14px;
      color: #6e737d;
      line-height: 18px;
      white-space: pre-line;
    }
  }
}

.manage-field-wrapper {
  &-header {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-bottom: 20px;

    &-title {
      font-weight: 500;
      font-size: 18px;
      line-height: 30px;
      color: #b1b1c1;
    }
  }
}

.manage-field {
  display: flex;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }

  &.row {
    flex-direction: row;
    align-items: center;
    gap: 10px;
    padding-left: 10px;
    min-height: 30px;

    .manage-field-label {
      max-width: 260px;
      min-width: 260px;
      width: 260px;
    }
  }

  &.column {
    flex-direction: column;
    padding-left: 10px;

    .manage-field-label {
      margin-bottom: 10px;
      min-height: 30px;
    }
  }
}

.manage-field-label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;

  &-info {
    display: flex;
    align-items: center;

    &-text {
      font-size: 16px;
      font-weight: 500;
      color: $fc-sub;
    }

    &-optional {
      margin-left: 5px;
      font-size: 12px;
      color: $fc-sub;
    }

    &-announcement {
      margin-left: 6px;
    }
  }

  &-description {
    margin-top: 6px;
    font-size: 12px;
    color: $fc-sub;
  }
}
