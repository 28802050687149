@import '../../../styles/variable';
.notification{
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  background-color: $color-white;
  transition: background-color ease-in-out 0.1s;
  cursor: pointer;
  width: 100%;
  padding: 10px 15px;
  &:hover{
    background-color: #F4F6F8;
  }
  &:not(:last-child){
    margin-bottom: 5px;
  }
  &-content{
    display: flex;
    flex: 1;
    flex-direction: column;
  }

  .delete-icon-wrapper{
    display: flex;
    align-items: center;
    width: 18px;
    .delete-notification-icon{
      width: 18px;
      object-fit: contain;
    }
  }
}

.unread-mark{
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  margin-top: 5px;
  margin-right: 5px;
}
