@import "../../../../styles/variable";
@import "../../../../styles/mixins";

.notification-panel {
  @include Wrapper_30_20(column);
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translateY(calc(100% + 12px));
  border: 1px solid $color-border-liner;
  border-radius: 15px;
  background: $color-white;
  box-shadow: 0 2px 20px 0 rgba(88, 89, 119, 0.16);
  z-index: 9999;
  width: 410px;

  .notification-panel-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 10px;
  }
}
