@import "../../../../styles/variable";

#SignIn {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;

  .sign-in {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    &-container {
      display: flex;
      flex-direction: column;
      gap: 80px;
      width: 520px;
      padding: 40px;
      border-radius: 20px;
      background-color: $color-white;

      .sign-in-header {
        display: flex;
        flex-direction: column;
        padding-bottom: 40px;
        border-bottom: 1px solid #ececec;

        &-title {
          margin-bottom: 10px;

          &-text {
            margin-right: 12px;
            font-weight: 500;
            font-size: 40px;
            line-height: 46px;
          }

          &-logo {
            height: 30px;
            object-fit: contain;
          }
        }

        &-comment {
          white-space: pre-line;
          font-size: 14px;
          line-height: 20px;
          color: $fc-main;
        }
      }

      .sign-in-body {
        width: 100%;
        margin-bottom: 10px;
        overflow: hidden;

        &-input-container {
          border: 2px solid $color-border-liner;
          border-radius: 10px;
          margin-bottom: 5px;

          .sign-in-body-input-box {
            display: flex;
            align-items: center;
            gap: 15px;
            padding: 14px 20px;

            .sign-in-body-input-icon {
              width: 22px;
              object-fit: contain;
            }

            .sign-in-body-input {
              flex: 1;
              border: none;

              &::placeholder {
                color: $fc-placeholder;
              }

              &:focus {
                outline: none;
              }
            }
          }
        }

        &-function-container {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 10px;
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;

          .sign-in-body-notice {
            color: $color-red;
          }

          .sign-in-body-find-password {
            color: $color-primary;
            cursor: pointer;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }

      .sign-in-button-container {
        display: flex;
        justify-content: space-between;

        .sign-in-button {
          width: 215px;
          height: 50px;
          border-radius: 8px;
          font-weight: 500;
          font-size: 18px;

          &.sign-up {
            background-color: $color-white;
            color: #7f879e;
            border: 1px solid #d8d8d8;
          }

          &.sign-in {
            background-color: $color-primary;
            color: $color-white;
          }
        }
      }
    }
  }

  .otp-authentication-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 560px;
    padding: 150px 0 0;

    .otp-authentication-logo {
      margin-bottom: 48px;
      height: 30px;
      object-fit: contain;
    }

    .otp-authentication-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 20px;

      &-body {
        margin-bottom: 40px;
        width: 100%;
      }

      &-button-container {
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
      }

      &-cs {
        white-space: pre-line;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #b1b1c1;

        &-email {
          color: $color-primary;
        }
      }
    }
  }

  .copyright {
    width: 100%;
    position: fixed;
    bottom: 18px;
    font-size: 12px;
    text-align: center;
    color: $fc-main;
  }
}
