@import "../../styles/variable";

#MainLayout {
  position: relative;
  //display: grid;
  //grid-template-columns: 260px 1fr;
  width: 100%;
  height: 100%;
  padding-top: $header-height;

  main {
    background-color: #fff;
    overflow: auto;
  }

  .help-button {
    position: fixed;
    bottom: 76px;
    right: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    width: 40px;
    height: 40px;
    border-radius: 16px;
    background-color: $color-primary;
    overflow: hidden;
    transition: 0.15s ease-in-out;
    z-index: 9999;
    cursor: pointer;

    &:hover {
      width: 92px;

      &::after {
        content: "Get Help";
        color: #fff;
        font-size: 12px;
        font-weight: 500;
        word-break: keep-all;
        white-space: nowrap;
      }
    }

    &-icon {
      width: 24px;
      height: 24px;
    }
  }
}
