@import "../../../../../styles/variable";

.wallet-card-setting {
  display: flex;
  flex-direction: row;
  gap: 30px;

  &-button {
    display: flex;
    flex-direction: column;
    gap: 20px;
    flex: 1;

    &-row {
      display: flex;
      flex-direction: row;
      gap: 10px;
    }
    &-index {
      display: flex;
      align-items: center;
      width: 76px;
      color: $fc-sub;
      font-weight: 500;
    }
  }
}
.wallet-card-uploader {
  input[type="file"] {
    display: none;
  }
}
