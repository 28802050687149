.basic-button-container {
  display: flex;
  width: fit-content;

  button {
    &:not(:last-child) {
      margin-right: 10px;
    }
  }
}
