
.paging-bar-wrapper {
  display: flex;
  width: fit-content;
  padding: 5px;

  .paging-page {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.01em;
    color: #3D425A;
    border-radius: 6px;
    white-space: pre;

    .paging-page-num {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 26px;
      height: 26px;
      border-radius: 50%;
      transition: all 0.2s;
      cursor: pointer;

      .paging-page-num-text {
        color: var(--pagingBar-text_active-color);
      }

      &:hover {
        background-color: #f9f9f9;
      }

      &:active {
        background-color: #f5f5f5;

        .paging-page-num-text {
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
          letter-spacing: -0.01em;
        }
      }

      &.active {
        .paging-page-num-text {
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
          letter-spacing: -0.01em;
          color: #3b37ff;
        }
      }
    }

    &.btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 26px;
      height: 26px;
      border-radius: 13px;

      stroke: #D8D8D8;

      &.active {
        stroke: #7F879E;
        cursor: pointer;

        &:hover {
          background-color: #f9f9f9;
        }

        &:active {
          background-color: #f5f5f5;
        }
      }
    }
  }
}
