.sorting-table-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 377px;

  .sorting-table {
    margin-bottom: 20px;
    width: 100%;
    border-collapse: separate !important;
    table-layout: fixed;
    overflow: hidden;
    border-radius: 16px;
    background-color: #fff;

    thead {
      height: 50px;

      tr {
        th {
          padding: 0 10px;
          border-bottom: 1px solid #b1b1c1;
          white-space: nowrap;
          text-align: left;
          vertical-align: middle;
          color: #7f879e;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 18px;

          .table-header-cell {
            display: flex;
            align-items: center;

            .table-sorting-arrows-wrapper {
              display: flex;
              flex-direction: column;
              margin-left: 5px;

              .table-sorting-arrow-wrapper {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 15px;
                height: 8px;
                border-radius: 5px;
                cursor: pointer;

                &:hover {
                  background-color: #cacacab5;
                }

                .table-sorting-arrow-icon {
                  display: flex;
                  width: 7px;
                  filter: grayscale(100%);

                  &.asc {
                    transform: rotate(180deg);
                  }

                  &.selected {
                    filter: none;
                  }
                }
              }
            }
          }
        }
      }
    }

    tbody {
      tr {
        cursor: pointer;

        &:hover {
          background-color: #f7f8fc;
        }

        &.no-data-row {
          cursor: default;

          &:hover {
            background-color: #fff;
          }
        }

        td {
          font-size: 14px;
          font-weight: 500;
          line-height: 18px;
          vertical-align: middle;
          padding: 10px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          color: #1d2026;
          border-bottom: 1px solid #efefef;

          .sorting-table-data {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;

            &.bold {
              font-weight: bold;
            }

            &.gray {
              color: #7f879e;
            }
          }
        }
      }
    }

    .no-data-row {
      td {
        font-weight: 500;
        font-size: 18px;
        text-align: center;
        padding: 25px 0;
      }
    }
  }
}
