.kit-label-value-wrapper {
  display: flex;
  width: 100%;
  gap: 10px;

  &.column {
    flex-direction: column;
    gap: 4px;
  }

  .kit-label {
    display: flex;
    align-items: center;
    min-width: 260px;
    font-size: 16px;
    font-weight: 500;
    line-height: 30px;
    color: #7D828D;

    &.required {
      color: #E70707;
    }
  }

  .kit-label-description {
    margin-top: -4px;
    margin-bottom: 16px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #7F879E;
  }

  .kit-value {
    display: flex;
    align-items: center;
    flex: 1;
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
  }

  .kit-info-image {
    width: 80px;
    height: 80px;
    border: 1px solid #EFEFEF;
    border-radius: 40px;
    object-fit: contain;
  }
}
