.faq-content {
  display: flex;
  flex-direction: column;
  .faq-title {
    margin-bottom: 40px;
    .faq-title__title {
      // margin-bottom: 15px;
      font-weight: 500;
      font-size: 28px;
      line-height: 36px;
    }
    .faq-title__sub-title {
      font-weight: 400;
      font-size: 16px;
      line-height: 21px;
    }
  }
  .faq-content__toggle {
    margin-bottom: 30px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
