@import "../../../../styles/variable";

.user-info-panel {
  position: absolute;
  bottom: 0;
  transform: translateY(calc(100% + 12px)) translateX(42px);
  right: 0;
  border: 1px solid $color-border-liner;
  border-radius: 15px;
  background-color: $color-white;
  box-shadow: 0 2px 20px 0 rgba(88, 89, 119, 0.16);
  box-sizing: border-box;
  z-index: 999;
  width: 308px;

  .user-info-panel-option-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid $color-border-liner;
    cursor: pointer;

    &:first-child {
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
    }

    &:last-child {
      border-bottom: none;
      border-bottom-right-radius: 15px;
      border-bottom-left-radius: 15px;
    }

    &:hover {
      background-color: #f4f6f8;
    }

    .user-info-panel-option {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 64px;
      padding: 0 22px;

      .user-info-panel-name {
        font-weight: $fw-medium;
        line-height: 21px;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 188px;
      }

      .user-info-option-label {
        font-size: 12px;
        color: #343434;
      }

      .user-info-option-icon {
        width: 22px;
        object-fit: contain;
      }
    }

    .account-select-panel {
      position: absolute;
      top: 100%;
      right: 10px;
      border: solid 1px $color-border-liner;
      border-radius: 10px;
      background-color: $color-white;
      box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.16);
      overflow: auto;
      z-index: 9999;
      width: 100%;
      height: fit-content;
      max-height: 260px;

      .account-option-wrapper {
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid $color-border-liner;

        &:last-child {
          border-bottom: none;
        }

        .account-option {
          display: flex;
          align-items: center;
          height: 50px;
          padding: 0 22px;

          &:hover {
            background-color: #f4f6f8;
          }
        }
      }
    }
  }

  .user-email {
    border-bottom: solid 2px $color-border-liner;
    font-size: 14px;
    line-height: 1.71;
    letter-spacing: normal;
    color: #6a7076;
    padding: 20px 27px;
  }

  .account-wrapper {
    :nth-child(n):not(:last-child) {
      border-bottom: solid 1px $color-border-liner;
    }

    .account {
      font-size: 16px;
      line-height: 1.5;
      letter-spacing: normal;
      color: #33373b;
      padding: 20px 27px;
    }
  }

  .sign-out {
    border-top: solid 2px $color-border-liner;
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: normal;
    color: #33373b;
    padding: 20px 27px;
  }
}
