@import "../../../../styles/variable";

.contract-method-viewer-container {
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  border: 1px solid #ececec;
  border-radius: 16px;
  box-shadow: $box-shadow-common;
  background-color: $color-white;
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 10px;
  }
  .contract-method-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
    cursor: pointer;
    .contract-method-name {
      font-weight: $fw-medium;
    }
    .contract-method-arrow {
      width: 10px;
      object-fit: contain;
    }
  }
  .contract-method-detail-wrapper {
    padding-bottom: 20px;
    .contract-method-detail-data {
      color: $fc-main;
      line-height: 21px;
    }
  }
}

.contract-method-input-container {
  margin-top: 20px;
  &:not(:last-child) {
    margin: 20px 0 25px;
  }
  .contract-method-input-detail {
    margin-bottom: 8px;
  }
  .contract-input-method-detail-data {
    color: $fc-main;
    line-height: 18px;
    white-space: pre-line;
  }
}
