@import "../../styles/variable";

.recommended-change-password-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  width: 564px;

  &-description {
    white-space: pre-line;
    text-align: center;
    line-height: 18px;
    color: $fc-main;
  }

  &-button-container {
    display: flex;
    justify-content: center;
  }
}
