@import "../../../styles/variable";

.basic-table {
  box-sizing: border-box;
  table-layout: fixed;
  //border-collapse: separate !important;
  overflow: hidden;
  width: 100%;
  --rowHeight: 40px;
  --headRowHeight: 30px;
  .basic-table-head {
    //background-color: #f4f6f8;
    background-color: transparent;
    height: var(--headRowHeight);

    &-row {
      height: var(--headRowHeight);
      border-bottom: 1px solid #EFEFEF;
      &-data {
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%;
        text-align: left;
        color: #7F879E;
        vertical-align: middle;
        padding: 0 10px;
      }
    }
  }

  .basic-table-body {
    width: 100%;
    &-row {
      height: var(--rowHeight);
      &-data {
        border-bottom: 1px solid #EFEFEF;
        padding: 0 10px;
        vertical-align: middle;
        font-size: 14px;
        font-weight: 500;
        line-height: 130%;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: #252A3F;
        overflow: hidden;
      }
    }
    .table-no-data{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 90px;

    }
  }
}
