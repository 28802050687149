@import "../../../../view/styles/variable";

.kit-image-uploader {
  display: flex;
  flex-direction: column;
  align-items: center;

  .preview {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 130px;
    height: 130px;
    border: 2px dashed $color-border-liner;
    border-radius: 8px;
    background-color: $color-white;
    color: $fc-placeholder;
    overflow: hidden;
    cursor: pointer;

    .preview-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .upload-comment {
    display: flex;
    align-items: center;
    height: 30px;
    font-weight: $fw-regular;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: $fc-placeholder;
    white-space: pre-line;
  }

  input[type="file"] {
    display: none;
  }
}
