@import "../../../../view/styles/variable";

//TODO: word-break or ellipsis 처리 필요

/// Type ///
.TT__page-title {
  font-size: $fs-36;
  font-weight: $fw-medium;
  line-height: 46px;
}

.TT__page-title-description {
  font-size: $fs-16;
  font-weight: $fw-regular;
  line-height: 21px;
}

.TT__content-title {
  font-size: $fs-16;
  font-weight: $fw-medium;
  line-height: 21px;
}

.TT__content {
  font-size: $fs-16;
  font-weight: $fw-regular;
  line-height: 21px;
}

.TT__section-title {
  font-size: $fs-20;
  font-weight: $fw-medium;
  line-height: 26px;
}

.TT__section-description {
  font-size: $fs-14;
  font-weight: $fw-regular;
  line-height: 18px;
}

/// Color ///

.TC__black {
  color: $color-black;
}

.TC__white {
  color: $color-white;
}

.TC__blue {
  color: $color-blue;
}

.TC__dark-gray {
  color: $color-dark-gray;
}

.TC__gray {
  color: $fc-placeholder;
}
