@import "../../styles/variable";
@import "../../styles/_mixins";

#DashboardAPI {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;

  .dashboard-api-area {
    display: flex;
    align-items: center;
    gap: 20px;
    width: 100%;

    &-container {
      width: 100%;
      min-height: 250px;
      height: 100%;
      background-color: #fff;
      border-radius: 16px;
    }
  }

  .dashboard-api-go-to-docs {
    color: $color-primary;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  .dashboard-api-empty-web3-endpoint {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 168px;
    text-align: center;
    color: #7d828d;
    font-size: 14px;
    font-weight: 500;
    white-space: pre-line;
  }
}
