#ProjectPricing {
  width: 1200px;
  margin: 0 auto;
  padding: 0 50px;

  .project-pricing-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;

    .project-pricing {
      &-header {
        &-title {
          margin-bottom: 8px;
          font-weight: 700;
          font-size: 32px;
          line-height: 41.6px;
          text-align: center;
        }

        &-description {
          font-size: 18px;
          font-weight: 400;
          line-height: 29px;
          text-align: center;
        }
      }
    }
  }
}
