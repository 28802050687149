@import "../../../../styles/variable";

.infra-auto-error-message {
  text-align: center;
  line-height: 1.33;
  font-size: $fs-index;
  font-weight: $fw-medium;
  color: $color-red;
  white-space: pre-line;
}

.manage-wallet-button-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
}
