@import "../../../styles/variable";

.basic-textarea {
  border: solid 2px $color-border-liner;
  border-radius: 8px;
  background-color: $color-white;
  font-size: $fs-normal;
  color: $fc-main;
  resize: none;
  width: 100%;
  padding: 14px 20px;
  &::placeholder {
    color: $fc-placeholder;
  }
  &:focus {
    outline: none;
  }
  &:read-only {
    border: none;
    border-radius: 0;
    outline: none;
    background-color: transparent;
    resize: none;
    padding: 0;
    height: auto;
  }
  &::-webkit-scrollbar {
    width: 10px;
    height: 8px;
  }
  /* Add a thumb */
  &::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-color: #dddddd;
  }
}
