@import "../../../styles/variable";

.remove-member-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 565px;

  &-user {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;

    &-info {
      display: flex;
      flex: 1;
      align-items: center;
      font-size: 16px;
      font-weight: 500;
      line-height: 21px;
      color: $fc-main;
      padding: 17px 10px;

      &-profile {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        margin-right: 16px;
        width: 40px;
        height: 40px;

        &-image {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &-name {
      }

      &-email {
      }
    }
  }

  &-check {
    margin-bottom: 20px;
    width: 100%;

    &-label {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      &-square {
        background-color: #b1b1c1;
        margin-right: 10px;
        width: 5px;
        height: 5px;
      }

      &-text {
        font-size: 16px;
        font-weight: 500;
        line-height: 21px;
        color: #848a96;
      }
    }
  }

  &-description {
    margin-bottom: 40px;
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    color: $fc-sub;
    width: 100%;
    //white-space: pre-line;
  }

  &-button-wrapper {
  }
}
