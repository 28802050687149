@import "../../../styles/variable";

.change-password-modal {
  display: flex;
  width: 564px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;

  &-body {
    display: flex;
    flex-direction: column;
    gap: 40px;

    .change-password-modal-image {
      object-fit: contain;

      &.send-email {
        height: 89px;
      }

      &.complete {
        height: 78px;
      }
    }
  }

  &-description-container {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .change-password-modal-description {
      white-space: pre-line;
      line-height: 18px;
      text-align: center;
      color: $fc-main;

      &.strong {
        font-weight: 500;
        font-size: 20px;
        line-height: 23px;
      }
    }
  }
}
