@import "../../../../styles/variable";

.index {
  &-18 {
    font-weight: $fw-medium;
    font-size: $fs-index;
    color: $fc-main;
  }
  &-16 {
    font-weight: $fw-medium;
    font-size: $fs-normal;
    color: $fc-main;
  }
}
.sub-text {
  color: #7E7E7E;
  font-size: 13px;
  font-weight: 400;
  line-height: normal;
}

.method-container {
  display: flex;
  flex-direction: row;
}

.warn-comment-list {
  margin-left: 15px;
  list-style-type: square;
  line-height: 21px;
}
