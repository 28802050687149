// noinspection ALL
@import-normalize;
@import "view/styles/_mixins.scss";
@import "view/styles/_variable.scss";

@mixin font-face($name, $file, $weight: noraml, $style: normal) {
  @font-face {
    font-family: "#{$name}";
    font-weight: $weight;
    font-style: $style;
    src: url("./view/assets/fonts/#{$file}.otf") format("truetype");
  }
}

// IBMPlexSans
@include font-face("DINPro", "DINPro-Light", 300);
@include font-face("DINPro", "DINPro-Regular", normal);
@include font-face("DINPro", "DINPro-Medium", 500);
@include font-face("DINPro", "DINPro-Bold", bold);
@include font-face("DINPro", "DINPro-Black", 800);

#root {
  width: 100%;
  height: 100%;
  background-color: $color-eqhub-default-background-color;
}

body {
  font-family: "DINPro", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
  "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  color: $fc-main;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

paper-toast {
  z-index: 1000;
}

.cursor-pointer {
  cursor: pointer;
}

.flex {
  display: flex;
}

button {
  font-family: inherit;
}

button img {
  float: left;
}

.justify-content {
  &-center {
    justify-content: center;
  }

  &-end {
    justify-content: flex-end;
  }

  &-space-between {
    justify-content: space-between;
  }

  &-space-around {
    justify-content: space-around;
  }
}

.align-items {
  &-center {
    align-items: center;
  }
}

.flex-column {
  display: flex;
  flex-direction: column !important;
}

.flex {
  display: flex;
}

.justify-content-flex-end {
  justify-content: flex-end;
}

.flex-vertical-align {
  flex-direction: row;
  align-items: center;
}

.activation-circle {
  border-radius: 50%;
  background-color: black;
  width: 8px;
  height: 8px;

  &.inactive {
    background-color: red;
  }

  &.active {
    background-color: #22de8f;
  }
}

.modal-overlay-style {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1001;
}

.list-btn-wrapper {
  position: relative;
  margin: 60px auto 0;
  padding-top: 60px;
  width: 995px;

  &:before {
    position: absolute;
    top: 0;
    left: calc((995px - 862px) / 2);
    background-color: #cecece;
    content: "";
    width: 862px;
    height: 1px;
  }
}

input {
  &:focus {
    outline: none;
  }
}

.table-image {
  border: 1px solid $color-border-liner;
  border-radius: 8px;
  background: $color-white;
  width: 74px;
  height: 74px;
  object-fit: cover;
}

.highlight-text {
  font-weight: $fw-bold;
}

.page-article {
  position: relative;
}

.page-layout-810 {
  @include article-810;
  padding-bottom: 40px;
}

.page-layout-812 {
  @include article-812;

  &.padding-top-60 {
    padding-top: 60px;
  }

  &.none-top-padding {
    padding: 0 40px 0;
  }

  &.sticky-point {
    z-index: 100;
    position: sticky;
    top: -1px;
    background-color: #f5f6f8;
  }
}

.page-layout-1080 {
  @include article-1080;
}

.page-layout-1240 {
  @include article-1240;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
