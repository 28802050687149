@import "../../../../styles/variable";

.method-select-wrapper {
  display: flex;
  flex-direction: column;
  width: 220px;
  background-color: $color-white;
  &:not(:last-child) {
    margin-bottom: 20px;
  }
  &-header {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    &-title {
      margin-bottom: 3px;
      font-size: $fs-index;
      color: $fc-main;
      font-weight: $fw-medium;
    }
    &-description {
      font-size: $fs-comment;
      color: #7e7e7e;
      font-weight: $fw-regular;
    }
  }
  &-method-box {
    display: flex;
    flex-direction: column;
    height: 315px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 10px;
    background: $color-white;
    border: 1px solid #ececec;
    border-radius: 8px;
    &-row {
      &:not(:last-child) {
        margin-bottom: 5px;
      }
      overflow-x: clip;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding: 8px 5px;
      border-radius: 4px;
      cursor: pointer;
      &.selected {
        background-color: rgba(88, 136, 255, 0.1);
        color: $color-blue;
        font-weight: $fw-bold;
      }
    }
    &::-webkit-scrollbar {
      width: 5px;
      height: 8px;
      background-color: #b3b6be26;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: #b3b6be;
    }
  }
}
