@import "../../../../styles/variable";

.summary-box-wrapper {
  position: absolute;
  top: 50px;
  // left: `calc( ${page size = 800}px + ${pageTemplatePadding = 100}px - 50px + 10px )`
  left: 880px;
  height: 100%;
}
.estimated-cost-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  .estimated-cost-data {
    color: $color-blue;
    font-weight: $fw-medium;
    font-size: 20px;
  }

  .estimated-cost-label {
    color: $fc-main;
    font-weight: $fw-medium;
    font-size: $fs-normal;
  }
}

.percent-icon {
  position: absolute;
  top: 17px;
  left: 96px;
}

.create-network-visualization-allocation {
  display: flex;
  flex-direction: column;
  align-items: center;

  .chart-label {
    font-size: $fs-small;

    &-name {
      font-weight: $fw-medium;
    }

    &-percent {
      font-size: 10px;
      font-weight: $fw-regular;
    }
  }

  &-data {
    width: 100%;

    &-row {
      display: flex;
      justify-content: space-between;
      margin: 0 0 20px;
      color: $fc-main;

      &:last-child {
        justify-content: center;
        margin: 0;
      }

      &-label {
        font-size: $fs-normal;
        font-weight: $fw-regular;
      }

      &-value {
        font-size: $fs-index;
        font-weight: $fw-medium;
      }

      &-allotted {
        text-align: right;
        color: $fc-placeholder;

        &.over {
          color: $color-red;
        }

        &.enough {
          color: $color-green;
        }

        &.insufficient {
          color: $color-blue;
        }

        &-percent {
          margin: 0 0 5px;
          font-size: 22px;
          font-weight: $fw-medium;
        }
        &-amount {
          font-size: 15px;

          &-balance {
          }

          &-symbol {
            font-size: $fs-small;
          }
        }
      }

      &-state {
        height: 32px;
        font-weight: $fw-medium;
        text-align: center;
        white-space: pre-line;

        &.over {
          color: $color-red;
        }

        &.enough {
          color: $color-green;
        }

        &.insufficient {
          color: $color-blue;
        }
      }
    }
  }
}

.pre-allocation-data-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 15px;
  width: 100%;
  color: $fc-main;
  height: 50px;

  &:last-child {
    margin: 0;
  }

  &-index {
  }
  &-value {
    font-size: $fs-index;
    font-weight: $fw-medium;
    line-height: 23px;

    &-symbol {
      font-size: $fs-comment;
    }
  }

  &-allocated-amount {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    font-size: $fs-index;
    font-weight: $fw-medium;
    line-height: 23px;
    color: $fc-placeholder;

    &.over {
      color: $color-red;
    }

    &.enough {
      color: $color-green;
    }

    &.insufficient {
      color: $color-blue;
    }

    &-percent {
      font-size: 22px;
    }

    &-value {
      font-size: $fs-16;
      font-weight: $fw-regular;

      &-symbol {
        font-size: $fs-14;
      }
    }
  }

  &-state {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    height: 32px;
    font-weight: $fw-medium;
    text-align: center;
    white-space: pre-line;

    &.over {
      color: $color-red;
    }

    &.enough {
      color: $color-green;
    }

    &.insufficient {
      color: $color-blue;
    }
  }
}

.node-setting-allocation-data {
  margin: 30px 0 0;
  &-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 16px;
    color: $fc-main;

    &:last-child {
      justify-content: center;
      margin: 0;
    }

    &-label {
      font-size: $fs-normal;
    }

    &-value {
      font-size: $fs-index;
      font-weight: $fw-medium;

      &-balance {
      }

      &-symbol {
        font-size: $fs-comment;
      }

      &.percent {
        color: $fc-placeholder;
        font-size: 22px;

        &.over {
          color: $color-red;
        }

        &.enough {
          color: $color-green;
        }

        &.insufficient {
          color: $color-blue;
        }
      }
    }

    &-state {
      height: 32px;
      font-weight: $fw-medium;
      text-align: center;
      white-space: pre-line;

      &.over {
        color: $color-red;
      }

      &.enough {
        color: $color-green;
      }

      &.insufficient {
        color: $color-blue;
      }
    }
  }
}

.network-button-container {
  margin-top: 40px;
  display: flex;
  justify-content: flex-end;
}
